import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import 'react-quill/dist/quill.snow.css';
import { chunk_array } from '../../../../util_functions';
import { useSelector } from 'react-redux';
import CustomCheckBox from '../../../util_components/custom_checkbox';

function GettingToKnowStudentBetter({ data }) {
  const { student_additional_info, student_uuid } = data;
  const [additionalInfo, setAdditionalInfo] = useState(student_additional_info);
  const [studentDetails, setStudentDetails] = useState(data);
  const [openOther, setOpenOther] = useState(false);
  console.log(studentDetails, 'data');

  const config_values = useSelector((state) => state.home.config_values);
  console.log(config_values, 'config_values');

  const handleChange = (value) => {
    setAdditionalInfo(value);
  };

  const handleOther = () => {
    setOpenOther(!openOther);
  };

  const updateAdditionalInfo = (info) => {
    const payload = {
      student_uuid,
      additional_info: info,
      student_additional_info: info,
      activity_frequency: studentDetails?.activity_frequency,
      activity_type: studentDetails?.activity_type,
      activity_duration: studentDetails?.activity_duration,
      activity_type_other: studentDetails.activity_type_other,
      goals: studentDetails?.yoga_goals
    };
    const url = get_api_url('update_student_additional_info');
    post_api(url, payload, true).then((response) => {
      if (response?.data?.status === 'success') {
        window.location.reload();
      }
    });
  };

  const handleSave = () => {
    updateAdditionalInfo(additionalInfo);
  };

  const handleClear = () => {
    setAdditionalInfo('');
    updateAdditionalInfo('');
  };

  const edit_goals = (item) => {
    const array = studentDetails?.yoga_goals?.split(',');
    const item_index = array.indexOf(item);
    if (item_index < 0) {
      array.push(item);
    } else {
      array.splice(item_index, 1);
    }
    setStudentDetails((prevState) => ({
      ...prevState,
      yoga_goals: array.join(',')
    }));
  };

  const render_goals = () => {
    const chunked_goals = chunk_array(config_values?.student_goals, 2);
    const user_goals = studentDetails?.yoga_goals?.split(',');
    console.log(studentDetails, 'studentDetails');
    console.log(config_values, 'config_values');
    const latest_goals = [];
    const old_goals = [];
    for (const goal of user_goals) {
      if (config_values?.student_goals?.indexOf(goal) != -1) latest_goals?.push(goal);
      else old_goals?.push(goal);
    }
    console.log(old_goals, 'old_goals');
    return (
      <div>
        <table>
          <tbody>
            {chunked_goals.map((arrays, index) => (
              <tr key={index}>
                {arrays.map((item, new_index) => {
                  const item_index = latest_goals.indexOf(item);
                  return (
                    <td key={new_index}>
                      <div className="profile-yoga-goals">
                        <CustomCheckBox
                          label={item}
                          checked={item_index > -1}
                          onClick={() => edit_goals(item, item_index)}
                        />
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="student-old-goals">
          {old_goals.map((goal) => (
            <div className="student-old-goals-div" key={goal}>
              <span>{goal}</span>
              <img
                src="https://images.myyogateacher.com/icons/ic_cross@2x.png"
                alt="x"
                onClick={() => edit_goals(goal)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="">
      <div className="mb-10 ">
        <div className="font-bold text-[30px]">Goals</div>
        {render_goals()}
      </div>
      <button onClick={handleOther}>Other</button>
      {openOther && (
        <div className="mt-10">
          <ReactQuill theme="snow" value={additionalInfo} onChange={handleChange} />
        </div>
      )}
      <div className="w-full flex align-center justify-end mt-4">
        <button onClick={handleSave}>Save</button>
        <button onClick={handleClear}>Clear</button>
      </div>
    </div>
  );
}

export default GettingToKnowStudentBetter;
