import React, { useState } from 'react';
import { useSelector } from 'react-redux';

function CaptureActivityLevel() {
  const [data, setData] = useState();
  const config_values = useSelector((state) => state.home.config_values);
  console.log(config_values, 'config_values');
  return (
    <div>
      <div className="font-bold text-[18px] text-black mb-5">
        1. How often does user currently exercise or engage in physical activity?
      </div>
      <ul className="list-disc px-6 ">
        {Object.entries(config_values?.activity_frequencys).map(([key, value]) => (
            <li className="font-medium text-[14px]" key={key}>
              {value}
            </li>
          ))}
      </ul>
      <div className="font-bold text-[18px] text-black my-5">
        {' '}
        2. What type of exercises or physical activities does user currently participate in?
      </div>
      <ul className="list-disc px-6">
        {Object.entries(config_values?.activity_types).map(([key, value]) => (
            <li className="font-medium text-[14px]" key={key}>
              {value}
            </li>
          ))}
      </ul>
      <div className="font-bold text-[18px] text-black my-5">
        3. How long is each of typical workout sessions?
      </div>
      <ul className="list-disc px-6">
        {Object.entries(config_values?.activity_durations).map(([key, value]) => (
            <li className="font-medium text-[14px]" key={key}>
              {value}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default CaptureActivityLevel;
