import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import CustomLoading from '../../../util_components/custom_loading';
import CommonHeader from '../../../util_components/common_header';
import CustomModal from '../../../util_components/custom_modal';
import CustomSingleTable from '../../../util_components/custom_single_table';
import CreateSubscription from './create_subs';

import CustomSingleSelect from '../../../util_components/custom_single_select';
import HighlightSearch from '../../../util_components/highlight_search_text';
import CustomCheckbox from '../../../util_components/custom_checkbox';
import EditProfile from '../edit_student_profile';
import CustomInput from '../../../util_components/custom_input';
import Button from '../../../util_components/button';
import CustomDatePicker from '../../../util_components/custom_date_picker';

import { api_with_method, post_api } from '../../../../redux/api_funcs';
import BookSession from '../book_session';
import * as actions from '../../../../redux/action_creators';
import {
  image_error_replace,
  isEmpty,
  uploadDiagnosisReport,
  isViewOnly
} from '../../../../util_functions';
import { student_profile_format, student_profile_format_backpain } from '../../../../utils/formats';
import { get_api_url } from '../../../../utils/urls';
import {
  min_list,
  hours_list,
  time_format,
  day_format_year,
  genders,
  time_day_format,
  day_format
} from '../../../../constants/index';

import SmsWindow from '../../reports/sms_window';
import './profile.css';

import { PauseMetrics } from './pause_metrics';
import { convertToSentenceCase } from '../../../../utils/formatString';
import FileComponent from '../../../util_components/upload_file';
import HumanSkeletonDummy from './human_skeleton_dummy';
import { AboutMeText } from './about_me_text';
import GettingToKnowStudentBetter from '../know_student_better_popup';

import { openChatWithUserUUID } from '../../../chat/chat';
import UserRole from '../../../../../myt_chat/src/chat/enums/UserRole';
import AddCreditForMembership from './AddCreditForMembership';
import VacationPauseMembershipDetails from './VacationPauseMembership/VacationPauseMembershipDetails';
import CaptureActivityLevel from '../../capture_activity_level';

const initialPopupState = {
  open_pause_create: false,
  open_pause_update: false,
  open_pause_delete: false,
  open_mem_del: false,
  open_mem_res: false,
  membership_start_date: '',
  membership_end_date: '',
  membership_pause_type: '',
  membership_pause_reason: '',
  open_add_roadmap: false,
  open_create_roadmap: false,
  open_affliate: false,
  add_student_referral: false,
  open_family_plan: false,
  cancellation_reason: '',
  new_gender: '',
  cancellation_primary_reasons: [],
  cancellation_secondary_reasons: [],
  cancellation_other_reason: '',
  cancellation_feedback: '',
  unsubscribe_open: false,
  open_mem_ext: false,
  nextBillingDate: '',
  confirm_extension: false,
  open_better_back_package: false,
  show_card_error: false,
  error_msg: '',
  remove_family_member_popup: false,
  remove_reason: '',
  showAddCreditPopup: false,
  shouldFetchData: false,
  show_pause_error: false
};

const initialState = {
  loading: true,
  edit_profile: false,
  book_session: false,
  show_create_subscription: false,
  subs_next: false,
  subs_submit: false,
  subs_success: false,
  subs_fail: false,
  subs_id: '',
  subs_name: '',
  saved_cards_list: [],
  saved_cards_loaded: false,
  selected_card_id: '',
  add_credit: false,
  creditValue: '',
  creditReason: '',
  creditExpiry: '',
  nextBillingDate: '',
  roadmap_questions: [],
  student_details: [],
  future_sess_uuid: '',
  aff_loading: false,
  scholarship_open: false,
  scholarship_uuid: '',
  subscription_confirm: false,
  student_search_text: '',
  add_student_uuid: '',
  package_type: '1_on_1_session_packages',
  z_index_loading: false,
  show_raw_data: false,
  raw_data_search: '',
  ban_open: false,
  ban_reason: '',
  unban_open: false,
  credit_hours: 0,
  credit_mins: 0,
  new_age: 0,
  same_students_open: false,
  reset_trial_open: false,
  change_email_open: false,
  change_email: '',
  change_email_confirm: false,

  sms_data: [],
  send_sms_uuid: '',
  send_sms_value: false,
  send_sms_name: '',
  reply: '',

  open_change_prepaid: false,

  sms_window_open: false,
  sms_window_student: '',

  notes_open: false,
  new_notes: '',
  student_notes: [],

  show_concierge_modal: false,
  concierge_list: [],
  student_concierge_uuid: '',
  concierge: '',
  extend_trial_period_poup: false,
  extend_trial_date: '',
  confirm_extension: false,
  extend_trial_end_date: '',
  client_domain: '',
  is_student_from_bank_pain: false,
  client_membership: {},
  show_consult_form: false,
  show_upload_medical: false,
  file_url: '',
  show_back_pain_area: false,
  is_know_student_better_open: false,
  student_easy_filters: [],
  show_easy_filter_popup: false,
  show_easy_filter_history_popup: false,
  easy_filter_history: [],
  family_member_list: [],
  student_email: '',
  selected_student_email_uuid: '',
  remove_family_member_uuid: '',
  searched_email_list: [],
  show_extend_membership_error: false,
  extend_membership_error_msg: '',
  is_capture_activity_level: false,
  ...initialPopupState
};

class StudentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  notesRef = React.createRef();

  componentDidMount() {
    this.load_concierge_list();
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('id');
    this.uuid = uuid;
    if (this.state?.client_domain === 'backpain') {
      this.setState({ is_student_from_bank_pain: true });
    }
    this.setState({ student_uuid: uuid }, () => this.load_data());
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.membership_status === 'loading' && this.props.membership_status === 'success') {
      this.setState({ loading: true }, () => {
        this.load_student_details();
        this.close_cud_mem();
      });
    }
    if (prevProps.config_status === 'loading' && this.props.config_status === 'success') {
      this.set_type_packages();
      this.load_student_details();
    }

    if (
      prevProps.student_search_status === 'loading' &&
      this.props.student_search_status === 'success'
    ) {
      const searched_email_list = [];
      if (this.props.search_students_list && this.props.search_students_list.length > 0) {
        this.props.search_students_list.map((st) => {
          const { student_uuid } = st;
          if (student_uuid) {
            const fname = st.sq_fname ? st.sq_fname : '';
            const mname = st.sq_mname ? st.sq_mname : '';
            const lname = st.sq_lname ? st.sq_lname : '';
            searched_email_list.push({
              label: `${fname} ${mname} ${lname} (${st.email})`,
              value: student_uuid
            });
          }
        });
        this.setState({ searched_email_list: [...searched_email_list] });
      }
    }

    if (prevState.student_email.length !== this.state.student_email.length) {
      this.setState({ selected_student_email_uuid: '' });
    }
  }

  load_data = () => {
    if (this.props.config_status === 'success') {
      this.set_type_packages();
      this.load_student_details();
    }
    this.load_payment_details();
  };

  load_family_member_list = () => {
    this.setState({ loading: true }, () => {
      const { student_uuid } = this.state;

      const urls = get_api_url('/v2/payments/cms/get_student_family', null, true);
      const payload = { student_uuid };
      api_with_method('post', urls, payload, true)
        .then((res) => {
          this.setState(
            {
              loading: false,
              family_member_list: [...res?.data?.data]
            },
            () => this.props.set_loading_variable('student_search_status', 'none')
          );
        })
        .catch((e) => {
          this.setState({ loading: false });
        });
    });
  };

  load_concierge_list = async () => {
    const url = get_api_url(`/v2/cms_user/concierge_list`, null, true);
    try {
      const concierge_response = await api_with_method('get', url, null, true);
      const new_list = concierge_response.data.data.map((item) => ({
        value: item.student_concierge_uuid,
        label: item.student_concierge_name
      }));
      this.setState({ concierge_list: new_list });
    } catch (e) {
      console.log('error', e);
    }
  };

  load_student_details = () => {
    const { student_uuid } = this.state;
    const url = get_api_url('get_student_data');
    const payload = { student_uuid };
    const { config_values } = this.props;
    post_api(url, payload, true)
      .then((result) => {
        const details = result.data;
        let student_questions = [];
        let student_easy_filters = [];

        try {
          student_easy_filters = [...details?.student_easy_filters];
          student_questions = [...details?.roadmap_questions];
        } catch {}
        const roadmap_ques_ans = [];
        try {
          const roadmap_questions = config_values.roadmap_session_questions;
          for (const ques of roadmap_questions) {
            const index = student_questions.findIndex((q) => q.question === ques);
            let answer = '';
            if (index !== -1) {
              answer = student_questions[index].answer;
            }
            roadmap_ques_ans.push({ question: ques, answer: answer || '' });
          }
        } catch (e) {
          console.error(e);
        }
        const notes = [...details.notes];

        this.setState(
          {
            roadmap_questions: [...roadmap_ques_ans],
            student_details: {
              ...details,
              credits_expiry:
                result.data.credits_expiry !== ''
                  ? moment.utc(result.data.credits_expiry).format('YYYY-MM-DD')
                  : ''
            },
            loading: false,
            aff_loading: false,
            z_index_loading: false,
            concierge: details.student_concierge_uuid,
            extend_trial_date: moment(details.raw_data.trial_end_date).format('YYYY-MM-DD'),
            notes,
            client_domain: details.client_domain,
            is_student_from_bank_pain: details.client_domain === 'backpain',
            client_membership: details.client_membership,
            student_easy_filters: result?.data?.student_easy_filters || [],
            easy_filter_history: result?.data?.student_easy_filters_history || []
          },
          () => {
            // commented for now
            this.load_family_member_list();
          }
        );
      })
      .catch((e) => this.set_failure_notification(e, 'Student Details loading failed'));
  };

  set_failure_notification = (e, notif_msg) => {
    let err_message;
    try {
      err_message = e?.response?.data?.message;
      if (!err_message) err_message = e?.response?.data?.reason;
    } catch (err) {
      err_message = notif_msg || 'Some error occured. Please contact care team.';
    }
    this.props.set_notification_variable(true, 'error', err_message);
    this.setState({ loading: false });
  };

  load_payment_details = () => {
    const { student_uuid } = this.state;
    const url = get_api_url('get_all_cards_s');
    const payload = { student_uuid };
    post_api(url, payload, true)
      .then((result) =>
        this.setState({ saved_cards_list: [...result.data.cards], saved_cards_loaded: true })
      )
      .catch((e) => this.set_failure_notification(e, 'Payment Details loading failed'));
  };

  set_type_packages = () => {
    const { packages } = this.props.config_values.pricing_packages[this.state.package_type];
    for (const pc of packages) {
      pc.label = pc.package_name;
      pc.value = pc.uuid;
    }
    this.setState({ final_packages: packages });
  };

  render_extra_sessions = () => {
    const { extra_session_scheduled } = this.state.student_details;
    if (extra_session_scheduled) {
      return (
        <div className="stu-pro-extra-class">
          Extra chargable sessions this cycle: {extra_session_scheduled}
        </div>
      );
    }
    return null;
  };

  openChatScreen = () => {
    openChatWithUserUUID(this.state.student_uuid, UserRole.STUDENT);
  };

  handleSessionListEmpty = (isEmpty) => {
    this.setState({ show_pause_error: isEmpty });
  };

  open_repeat_sessions = () => {
    const search = `?uuid=${this.state.student_uuid}`;
    this.props.history.push({
      pathname: '/repeat_sessions',
      search
    });
  };

  add_student_referral = () => this.setState({ add_student_referral: true });

  student_tests = () =>
    this.props.history.push(`/studentprofile/tests/${this.state.student_details.slug}`);

  open_notifications = () =>
    this.props.history.push(`/student/notifications/${this.state.student_uuid}`);

  toggle_raw_data = () => this.setState({ show_raw_data: !this.state.show_raw_data });

  toggle_notes = () => this.setState({ notes_open: !this.state.notes_open, new_notes: '' });

  open_add_credit = () => this.setState({ add_credit: true });

  close_all_popups = () => this.setState({ book_session: false, add_credit: false });

  open_book_session = () => this.setState({ book_session: true });

  toggle_subscription = () =>
    this.setState({ show_create_subscription: !this.state.show_create_subscription });

  change_roadmap = (e) => this.setState({ future_sess_uuid: e.target.value });

  on_subs_select = (e) => this.setState({ subs_id: e.target.value });

  on_card_select = (e) => this.setState({ selected_card_id: e.target.value });

  on_subs_next = () => {
    if (this.state.subs_id) this.setState({ subs_next: true });
  };

  toggle_reset_trial = () => this.setState({ reset_trial_open: !this.state.reset_trial_open });

  toggle_ban = () => this.setState({ ban_open: !this.state.ban_open, ban_reason: '' });

  toggle_unban = () => this.setState({ unban_open: !this.state.unban_open });

  toggle_same_students = () =>
    this.setState({ same_students_open: !this.state.same_students_open });

  open_family_plan = () => this.setState({ open_family_plan: true });

  open_create_roadmap = () => this.setState({ open_create_roadmap: true });

  open_affliate = () => this.setState({ open_affliate: true });

  open_cud_mem = (type) => this.setState({ [type]: true });

  change_package_type = (type) =>
    this.setState({ package_type: type, subs_id: '' }, () => this.set_type_packages());

  cancel_subscription = () => this.setState({ subs_next: false });

  toggle_edit_profile = () => this.setState({ edit_profile: !this.state.edit_profile });

  toggle_change_prepaid = () =>
    this.setState({ open_change_prepaid: !this.state.open_change_prepaid });

  go_back = () => this.props.history.goBack();

  handle_change = (e) => this.setState({ [e.target.name]: e.target.value });

  handle_date_change = (name, val) => this.setState({ [name]: val });

  handle_number_change = (e) => this.setState({ [e.target.name]: e.target.value });

  open_sms_window = () =>
    this.setState({ sms_window_open: true, sms_window_student: this.state.student_uuid });

  close_sms_window = () => this.setState({ sms_window_open: false, sms_window_student: '' });

  unsubscribe_from_all_lists = () => this.setState({ unsubscribe_open: true });

  open_extend_trial_modal = () => this.setState({ extend_trial_period_poup: true });

  close_extend_trial_modal = () => this.setState({ extend_trial_period_poup: false });

  open_concierge_modal = () => this.setState({ show_concierge_modal: true });

  close_concierge_modal = () => this.setState({ show_concierge_modal: false });

  edit_profile_success = () =>
    this.setState({ edit_profile: false, loading: true }, () => {
      this.load_student_details();
    });

  recommendationsPlayground = () =>
    this.props.history.push(
      `/recommendations_playground?email=${encodeURIComponent(this.state.student_details.email)}`
    );

  toggle_scholarship_next = () =>
    this.setState({ subscription_confirm: !this.state.subscription_confirm });

  toggle_back_pain_area_popup = () =>
    this.setState({ show_back_pain_area: !this.state.show_back_pain_area });

  toggle_easy_filter_popup = () => this.setState({ show_easy_filter_popup: true });

  close_easy_filter = () => this.setState({ show_easy_filter_popup: false });

  toggle_easy_filter_history_popup = () => this.setState({ show_easy_filter_history_popup: true });

  close_easy_filter_history = () => this.setState({ show_easy_filter_history_popup: false });

  close_cud_mem = () => {
    this.setState(initialPopupState);
    this.props.set_loading_variable('show_pause_error', false);
    this.props.set_loading_variable('pause_error_response', '');
  };

  close_family_plan = () => {
    this.setState(
      {
        open_family_plan: false,
        student_email: '',
        selected_student_email_uuid: '',
        remove_reason: ''
      },
      () => {
        this.props.set_loading_variable('student_search_status', 'none');
      }
    );
  };

  close_subsciption = () => {
    this.setState(
      {
        show_create_subscription: false,
        subs_next: false,
        subs_submit: false,
        subs_success: false,
        subs_fail: false
      },
      () => {
        if (this.state.success) this.load_student_details();
      }
    );
  };

  load_package_details = () => {
    const url = get_api_url('get_all_myt_packages');
    post_api(url, null, true)
      .then((result) => {
        const subs_select_values = [];
        const subs_values = {};
        result.data.myt_packages.forEach((sub) => {
          subs_select_values.push({ value: sub.uuid, label: sub.name });
          subs_values[sub.uuid] = sub.name;
        });
        this.setState({ subs_values, subs_select_values });
      })
      .catch((e) => this.set_failure_notification(e, 'Loading package details failed'));
  };

  pause_membership_open = (type) => {
    const details = this.state.student_details;
    if (type === 'Update') {
      this.setState({
        membership_pause_type: type,
        open_pause_create: true,
        membership_start_date: details.membership_pause.from_date,
        membership_end_date: details.membership_pause.to_date
      });
    } else {
      this.setState({ membership_pause_type: type, open_pause_create: true });
    }
  };

  change_prepaid_to_4_weeks = () => {
    this.setState({ aff_loading: true }, () => {
      const url = get_api_url('change_prepaid_to_4_weeks');
      const payload = {
        student_uuid: this.state.student_uuid
      };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Student subscribed successfully');
          this.toggle_change_prepaid();
          this.load_student_details();
        })
        .catch((e) => {
          this.set_failure_notification(e);
          this.setState({ aff_loading: false });
        });
    });
  };

  impersonate_teacher = () => {
    const is_uk = this.state.student_details.iana_timezone === 'Europe/London' ? 1 : 0;
    this.props.impersonate_user(
      this.state.student_uuid,
      'STUDENT',
      is_uk,
      this.state.student_details.client_domain
    );
  };

  handleFileChange = (e) => {
    const url = get_api_url('update_student_photo');
    const data = new FormData();
    this.setState({ loading: true });
    data.append('image_data', e.target.files[0]);
    data.append('student_uuid', this.state.student_uuid);
    post_api(url, data, true, 'formdata')
      .then(() => this.load_student_details())
      .catch(() => this.setState({ loading: false }));
  };

  update_pic = () => {
    const realInput = document.getElementById('pic-input');
    realInput.click();
  };

  open_student_sessions = (e) => {
    const url = '/usersessions/future';
    const search = `?id=${this.state.student_uuid}&user_type=STUDENT&name=${this.state.student_details.name}`;
    this.props.history.push({
      pathname: url,
      search
    });
  };

  open_notification_preferences = () => {
    const url = '/student/notification_preferences';
    const search = `?id=${this.state.student_uuid}&name=${this.state.student_details.name}`;
    this.props.history.push({
      pathname: url,
      search
    });
  };

  open_credit_history = (e) => {
    const url = '/credithistory';
    const search = `?id=${this.state.student_uuid}&name=${this.state.student_details.name}`;
    this.props.history.push({
      pathname: url,
      search
    });
  };

  openMembershipCreditHistory = () => {
    const url = '/membership_credits_history';
    const search = `?id=${this.state.student_uuid}&name=${this.state.student_details?.name}`;
    this.props.history.push({
      pathname: url,
      search
    });
  };

  go_to_roadmap = () => {
    const search = `?id=${this.state.student_uuid}`;
    this.props.history.push({
      pathname: '/addroadmap',
      search
    });
  };

  open_transactions = () => {
    const search = `?id=${this.state.student_uuid}&name=${this.state.student_details.name}`;
    this.props.history.push({
      pathname: '/studenttransactions',
      search
    });
  };

  add_credits = () => {
    const { student_uuid, creditExpiry, creditValue, creditReason, credit_hours, credit_mins } =
      this.state;
    const no_of_credits = parseInt(credit_hours) + parseInt(credit_mins);
    if (no_of_credits === 0) {
      alert('Enter credit value');
      return;
    }

    if (!creditReason) {
      alert('Enter credit Reason');
      return;
    }

    const url = get_api_url('add_credits');

    const expiry = creditExpiry || moment().format('YYYY-MM-DD');

    const data = {
      student_uuid,
      no_of_credits: no_of_credits / 60,
      reason: creditReason,
      credits_expiry: `${expiry} 23:59`
    };

    this.setState({ z_index_loading: true }, () => {
      post_api(url, data, true)
        .then(() =>
          this.setState(
            { add_credit: false, creditValue: '', creditReason: '', creditExpiry: '' },
            () => {
              this.load_student_details();
            }
          )
        )
        .catch((e) => {
          this.set_failure_notification(e);
          this.setState({ z_index_loading: false });
        });
    });
  };

  submit_subscription = (payload) => {
    this.setState({ aff_loading: true }, () => {
      const url = get_api_url('charge_subscription');
      post_api(url, payload, true)
        .then(() => {
          this.setState({ show_create_subscription: false });
          this.props.set_notification_variable(true, 'success', 'Student subscribed successfully');
          this.load_student_details();
        })
        .catch((e) => {
          this.set_failure_notification(e);
          this.setState({ aff_loading: false });
        });
    });
  };

  submit_gift = (payload) => {
    this.setState({ aff_loading: true }, () => {
      const url = get_api_url('charge_gift_package');
      post_api(url, payload, true)
        .then(() => {
          this.setState({ show_create_subscription: false });
          this.props.set_notification_variable(true, 'success', 'Student subscribed successfully');
          this.load_student_details();
        })
        .catch((e) => {
          this.set_failure_notification(e);
          this.setState({ aff_loading: false });
        });
    });
  };

  delete_membership = () => {
    const {
      cancellation_primary_reasons,
      cancellation_secondary_reasons,
      cancellation_other_reason,
      cancellation_feedback
    } = this.state;
    const data = {
      type: 'delete',
      payload: {
        student_uuid: this.state.student_details.student_uuid,
        cancellation_primary_reasons,
        cancellation_secondary_reasons,
        cancellation_other_reason,
        cancellation_feedback
      }
    };
    this.props.cud_membership(data);
  };

  toggle_primary_check_box = (option) => {
    const { cancellation_primary_reasons } = this.state;
    const reason_index = cancellation_primary_reasons.indexOf(option);
    if (reason_index === -1) {
      this.setState({ cancellation_primary_reasons: [...cancellation_primary_reasons, option] });
    } else {
      const filtered_reasons = cancellation_primary_reasons.filter((r) => r !== option);
      this.setState({ cancellation_primary_reasons: filtered_reasons });
    }
  };

  toggle_secodary_check_box = (option) => {
    const { cancellation_secondary_reasons } = this.state;
    const reason_index = cancellation_secondary_reasons.indexOf(option);
    if (reason_index === -1) {
      this.setState({
        cancellation_secondary_reasons: [...cancellation_secondary_reasons, option]
      });
    } else {
      this.setState({
        cancellation_secondary_reasons: cancellation_secondary_reasons.filter((r) => r !== option)
      });
    }
  };

  on_roadmap_change = (index, value) => {
    const ques = [...this.state.student_details.roadmap_questions];
    ques[index].answer = value;
    this.setState({
      roadmap_questions: { ...this.state.student_details.roadmap_questions, ...ques }
    });
  };

  save_roadmap = () => {
    this.setState({ loading: true }, () => {
      const { student_details, student_uuid, new_age, new_gender } = this.state;
      const { roadmap_questions } = student_details;
      const payload = { roadmap_data: JSON.stringify(roadmap_questions), student_uuid };
      if (!!new_gender && student_details.gender !== new_gender) {
        payload.gender = new_gender;
      }
      if (student_details.age !== new_age) {
        payload.age = new_age;
      }
      const url = get_api_url('save_roadmap_data');
      post_api(url, payload, true)
        .then((response) => {
          this.load_student_details();
          this.setState({ open_add_roadmap: false });
          this.props.set_notification_variable(true, 'success', 'Successfully updated');
        })
        .catch((e) => this.set_failure_notification(e));
    });
  };

  handle_number_change_new = (e) => {
    const val = e.target.value || 0;
    this.setState({
      [e.target.name]: parseInt(val, 10)
    });
  };

  create_roadmap = () => {
    this.setState({ loading: true }, () => {
      const { future_sess_uuid, student_uuid } = this.state;
      const payload = { student_uuid };
      const url = get_api_url('create_roadmap_new');
      post_api(url, payload, true)
        .then((resposne) => {
          this.setState({ open_add_roadmap: true, open_create_roadmap: false }, () =>
            this.load_student_details()
          );
        })
        .catch((e) => this.set_failure_notification(e));
    });
  };

  open_add_roadmap = () => {
    const { student_details } = this.state;
    const gender = student_details.gender ? student_details.gender : '';
    this.setState({ open_add_roadmap: true, new_age: student_details.age, new_gender: gender });
  };

  open_email_logs = () => {
    const search = `?id=${this.state.student_uuid}`;
    this.props.history.push({
      pathname: '/studentemaillogs',
      search
    });
  };

  add_affiliate = () => {
    this.setState({ aff_loading: true }, () => {
      const url = get_api_url('add_onboard_affiliate_to_student');
      const { student_uuid } = this.state.student_details;
      const payload = { student_uuid };
      post_api(url, payload, true)
        .then(() => {
          this.load_student_details();
          this.setState({ aff_loading: false, open_affliate: false });
          this.props.set_notification_variable(true, 'success', 'Added affiliate');
        })
        .catch((e) => {
          this.set_failure_notification(e);
          this.setState({ aff_loading: false });
        });
    });
  };

  add_student_to_family_plan = () => {
    this.setState({ aff_loading: true }, () => {
      const url = get_api_url('add_affiliate_to_student_cms');
      const { student_uuid } = this.state.student_details;
      const payload = { student_uuid, affiliate_uuid: '90e39a4d-9338-4218-bf5f-374b59b27f46' };
      post_api(url, payload, true)
        .then(() => {
          this.load_student_details();
          this.setState({ aff_loading: false, open_family_plan: false });
          this.props.set_notification_variable(true, 'success', 'Student added to family plan');
        })
        .catch((e) => {
          this.set_failure_notification(e);
          this.setState({ aff_loading: false });
        });
    });
  };

  send_email = () => {
    const details = this.state.student_details;
    this.props.history.push({
      pathname: '/createemail',
      state: {
        name: details.first_name,
        email: details.email
      }
    });
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  toggle_scholarship = () => {
    this.setState({
      scholarship_open: !this.state.scholarship_open,
      scholarship_uuid: '',
      subscription_confirm: false
    });
  };

  add_scholarship = () => {
    const { student_uuid } = this.state;
    this.setState({ loading: true }, () => {
      const url = get_api_url('add_free_membership_to_student_cms');
      const payload = {
        student_uuid,
        affiliate_uuid: '1902f0da-0f5e-46ab-8596-e73df0ad6481',
        package_uuid: 'd5bba8c0-eb5f-46bf-8561-480c65d33f62'
      };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Scholarship created successfully');
          this.toggle_scholarship();
          this.load_data();
        })
        .catch((e) => this.set_failure_notification(e));
    });
  };

  on_search_click = () => {
    const { student_search_text, student_email } = this.state;
    if (student_search_text?.length !== 0) {
      if (student_search_text.trim().length < 3) {
        this.props.set_notification_variable(
          true,
          'error',
          'Please type atlease 3 letters to search'
        );
      } else {
        this.props.search_students({
          search_param: student_search_text
        });
      }
    } else if (student_email?.length !== 0) {
      if (student_email.trim().length < 3) {
        this.props.set_notification_variable(
          true,
          'error',
          'Please type atlease 3 letters to search'
        );
      } else {
        this.props.search_students({
          search_param: student_email
        });
      }
    }
  };

  on_key_press = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.on_search_click();
    }
  };

  add_referral = () => {
    const { add_student_uuid, student_uuid } = this.state;
    const url = get_api_url('add_referral_for_student');
    const payload = {
      referred_student_uuid: add_student_uuid,
      referral_student_uuid: student_uuid
    };
    this.setState({ aff_loading: true }, () => {
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Referral added');
          this.setState(
            {
              add_student_referral: false,
              student_search_text: '',
              add_student_uuid: '',
              aff_loading: false
            },
            () => {
              this.props.set_loading_variable('student_search_status', 'none');
              this.load_data();
            }
          );
        })
        .catch((e) => {
          this.set_failure_notification(e);
          this.setState({ aff_loading: false });
        });
    });
  };

  ban_user = () => {
    this.setState({ loading: true }, () => {
      const url = get_api_url('/v2/cms_user/ban_student', false, true);
      const { student_uuid, ban_reason } = this.state;
      const payload = { student_uuid, reason: ban_reason };
      post_api(url, payload, true)
        .then(() => {
          this.toggle_ban();
          this.load_data();
        })
        .catch((e) => this.set_failure_notification(e));
    });
  };

  unban_user = () => {
    this.setState({ loading: true }, () => {
      const url = get_api_url('/v2/cms_user/unban_student', false, true);
      const { student_uuid } = this.state;
      const payload = { student_uuid };
      post_api(url, payload, true)
        .then(() => {
          this.toggle_unban();
          this.load_data();
        })
        .catch((e) => this.set_failure_notification(e));
    });
  };

  confirm_reset_trial = () => {
    this.setState({ loading: true }, () => {
      const url = get_api_url('reset_trial_period');
      const { student_uuid } = this.state;
      const payload = { student_uuid };
      post_api(url, payload, true)
        .then(() => {
          this.setState({ loading: false });
          this.toggle_reset_trial();
          this.load_data();
        })
        .catch((e) => {
          this.set_failure_notification(e);
          this.toggle_reset_trial();
        });
    });
  };

  confirm_change_email = () => {
    this.setState({ aff_loading: true }, () => {
      const url = get_api_url('change_user_email');
      const { student_uuid, change_email } = this.state;
      const payload = { student_uuid, new_email: change_email };
      post_api(url, payload, true)
        .then(() => {
          this.setState({ aff_loading: false });
          this.toggle_change_email();
          this.load_data();
        })
        .catch((e) => {
          this.setState({ aff_loading: false, change_email_confirm: false });
          this.set_failure_notification(e);
        });
    });
  };

  toggle_change_email = () => {
    this.setState({
      change_email_open: !this.state.change_email_open,
      change_email: '',
      change_email_confirm: false
    });
  };

  save_notes = () => {
    this.setState({ loading: true }, () => {
      const { student_uuid, new_notes, notes } = this.state;
      const url = get_api_url('save_notes');
      const payload = { student_uuid, notes: new_notes };
      post_api(url, payload, true)
        .then((res) => {
          const notes_res = res.data.notes;
          const note = {
            uuid: notes_res.uuid,
            notes: notes_res.notes,
            created_date: moment(),
            name: this.props.cms_user_name
          };
          const notes_1 = [...notes, note];
          this.setState({ notes: notes_1, loading: false, new_notes: 1 });
        })
        .catch((e) => this.set_failure_notification(e));
    });
  };

  scrollNotesToBottom = () => {
    try {
      const height = this.notesRef.current.scrollHeight;
      this.notesRef.current.scrollTo(0, height);
    } catch (e) {
      console.log(e);
    }
  };

  unsubscribe_confirm = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('unsubscribe_from_all_lists');
      const { student_uuid } = this.state;
      const payload = { student_uuid };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(
            true,
            'success',
            'Student unsubscribed successfully'
          );
          this.setState({ loading: false });
          this.close_cud_mem();
          this.load_data();
        })
        .catch((e) => this.set_failure_notification(e));
    });

  save_concierge = () => {
    const { student_details, concierge } = this.state;
    const payload = {
      student_uuid: student_details.student_uuid,
      concierge_uuid: concierge
    };

    const url = get_api_url(`/v2/cms_user/change_concierge`, null, true);
    this.setState({ loading: true }, () => {
      api_with_method('post', url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Concierge Added');
          this.close_concierge_modal();
          this.load_student_details();
        })
        .catch((e) => this.set_failure_notification(e));
    });
  };

  change_concierge_btn_name = () => {
    const { student_details } = this.state;
    const btn_name =
      student_details.student_concierge_uuid != ''
        ? `Concierge: ${student_details.student_concierge_name}`
        : student_details.student_concierge_uuid == ''
          ? `No concierge assigned, Add one`
          : null;
    return btn_name;
  };

  student_concierge_detail = () => {
    const { student_details } = this.state;
    const btn_name =
      student_details.student_concierge_uuid != ''
        ? `Concierge: ${student_details.student_concierge_name}`
        : 'No concierge assigned';
    return btn_name;
  };

  render_student_conierge = () => {
    const { concierge, concierge_list } = this.state;
    const teacherUrl = `/teacherprofile?id=${concierge}`;
    return (
      <div className="flex item-center justify-center">
        <div className="text-center">
          <a className="link-no-dec spc-btn-co-dup" href={teacherUrl} target="__blank">
            <button>{this.student_concierge_detail()}</button>
          </a>
        </div>
      </div>
    );
  };

  save_extend_trial_period_date = () => {
    const { extend_trial_date, student_details } = this.state;

    if (extend_trial_date) {
      const new_end_date = moment(extend_trial_date).tz(this.props.iana_timezone);
      const payload = {
        student_uuid: student_details.student_uuid,
        trial_end_date: new_end_date
      };

      const url = get_api_url('reset_trial_period');
      this.setState({ loading: true }, () => {
        post_api(url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Date Extended');
            this.close_extend_trial_modal();
            this.load_data();
          })
          .catch((e) => this.set_failure_notification(e));
      });
    } else {
      this.setState({ modal_error: true });
    }
  };

  extend_membership = () => {
    const { nextBillingDate, student_uuid } = this.state;
    const next_billing_date = moment(nextBillingDate).format('YYYY-MM-DD');
    const url = get_api_url('extend_membership');
    const payload = {
      student_uuid,
      next_billing_date
    };
    this.setState({ loading: true }, () => {
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Updated');
          this.close_cud_mem();
          this.load_student_details();
        })
        .catch((e) => {
          this.setState({
            loading: false,
            show_extend_membership_error: true,
            extend_membership_error_msg: e.response.data.message,
            confirm_extension: false
          });
          console.log('Extend Membership error');
        });
    });
  };

  render_easy_filter = () => {
    const { student_easy_filters } = this.state;
    return (
      <div className="easy-filter">
        <table className="border">
          <thead>
            <tr>
              <th className="text-white bg-mytBlue">Easy Filter Name</th>
              <th className="text-white bg-mytBlue">Easy Filter Score</th>
            </tr>
          </thead>
          <tbody>
            {student_easy_filters.map((item) => (
              <tr key={item.filter_id}>
                <td>{item.name}</td>
                <td>{item.filter_score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  render_easy_filter_history = () => {
    const { easy_filter_history } = this.state;
    return (
      <table className="border">
        <thead>
          <tr>
            <th className="text-white bg-mytBlue">Easy Filter Name</th>
            <th className="text-white bg-mytBlue">Easy Filter Score</th>
            <th className="text-white bg-mytBlue">Primary Goal Used</th>
            <th className="text-white bg-mytBlue">Secondary Goal Used</th>
            <th className="text-white bg-mytBlue">Filter Score Incerement</th>
            <th className="text-white bg-mytBlue">Session ID</th>
          </tr>
        </thead>
        <tbody>
          {easy_filter_history.map((item) => (
            <tr key={item.filter_id}>
              <td>{item.name}</td>
              <td>{item.filter_score}</td>
              <td>{item.primary_goal_used}</td>
              <td>{item.secondary_goal_used}</td>
              <td>{item.filter_score_increment}</td>
              <td>{item.session_id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  render_profile_details = () => {
    const { student_details } = this.state;
    const back_pain_location = convertToSentenceCase(
      student_details?.onboarding_survey?.back_pain_location?.replaceAll('_', ' ')
    );
    const str = student_details?.onboarding_survey?.back_pain_doctor_diagnosis;
    const updated_back_pain_doctor_diagnosis = str;
    const back_pain_doctor_diagnosis = convertToSentenceCase(
      updated_back_pain_doctor_diagnosis?.replaceAll('_', ' ')
    );
    const back_pain_duration = convertToSentenceCase(
      student_details?.onboarding_survey?.back_pain_duration
    );
    const back_pain_intensity = student_details?.onboarding_survey?.back_pain_intensity;
    const client_height =
      student_details?.onboarding_survey?.height_feet === undefined
        ? '--'
        : `${student_details?.onboarding_survey?.height_feet} ' ${student_details?.onboarding_survey?.height_inches} "`;
    const weight =
      student_details?.onboarding_survey?.weight === undefined
        ? '--'
        : `${student_details?.onboarding_survey?.weight} ${student_details?.onboarding_survey?.weight_type}` ||
          '--';
    const back_pain_doctor_other_diagnosis =
      student_details?.onboarding_survey?.back_pain_doctor_other_diagnosis;
    const back_pain_trigger = student_details?.onboarding_survey?.back_pain_trigger;
    const health_history_list = [
      ...student_details?.back_pain_diagnosis_reports,
      ...student_details?.health_history_list
    ];
    const session_price = student_details?.session_price || '--';
    const credits_available = '--';
    const sessions_remaining = student_details?.sessions_remaining;
    const trial_end_date = student_details?.raw_data?.trial_end_date;
    const newData = {
      ...student_details,
      back_pain_location,
      back_pain_doctor_diagnosis,
      back_pain_duration,
      back_pain_intensity,
      client_height,
      back_pain_doctor_other_diagnosis,
      weight,
      back_pain_trigger,
      health_history_list: health_history_list.map((a) => ({
        file_path: a.file_url,
        file_name: a.file_name
      })),
      session_price,
      sessions_remaining,
      trial_end_date
    };

    return (
      <div className="teacher-profile-details">
        {student_details?.client_domain === 'backpain' ? (
          <CustomSingleTable data={newData} data_format={student_profile_format_backpain} />
        ) : (
          <CustomSingleTable data={newData} data_format={student_profile_format} />
        )}
        {this.render_subscription_queue()}
        {this.render_subscription_details()}
      </div>
    );
  };

  render_subscription_table = () => {
    const details = this.state.student_details;
    const mem_details = details.subscription_info;
    const is_member = Object.keys(mem_details).length !== 0;
    const fu_details = details.subscription_queue;
    const is_fu_member = Object.keys(fu_details).length !== 0;
    const pause_details = details.membership_pause;
    const is_pause = Object.keys(pause_details).length !== 0;
    const final_details = {
      package_name: fu_details?.package_name,
      subscription_package_name: details?.subscription_package_name,
      subscription_start_date: fu_details?.subscription_start_date,
      last_transaction_date: details?.last_transaction_date,
      next_transaction_date: details?.next_transaction_date,
      from_date: pause_details?.from_date,
      to_date: pause_details?.to_date,
      status: mem_details?.subscription_status,
      cancellation_reason: mem_details?.cancellation_reason,
      cancellation_time: mem_details?.cancellation_time,
      is_free_membership: mem_details?.is_free_membership,
      is_student_family_package: details?.is_student_family_package,
      membership_paused: details?.membership_paused === 1 ? 'Paused' : '',
      is_prepaid: mem_details?.is_prepaid,
      prepaid_next_charge: mem_details?.prepaid_next_charge
    };

    const membership_format = [
      {
        title: 'Package Name',
        key: is_fu_member ? 'package_name' : 'subscription_package_name',
        full_row: true
      },
      {
        title: 'Membership start date',
        key: 'subscription_start_date',
        show_row: is_fu_member
      },
      {
        title: 'Last Transaction',
        key: 'last_transaction_date',
        show_row: !!details.last_transaction_date
      },
      {
        title: 'Subscription cancels at',
        key: 'next_transaction_date',
        show_row: mem_details.subscription_status === 'CANCELLED'
      },
      {
        title: 'Next Transaction',
        key: 'next_transaction_date',
        show_row: is_member && mem_details.subscription_status !== 'CANCELLED'
      },
      {
        title: 'Membership pause start',
        key: 'from_date',
        show_row: is_member && is_pause
      },
      {
        title: 'Membership pause end',
        key: 'to_date',
        show_row: is_member && is_pause
      },
      {
        title: 'Membership status',
        key: 'status'
      },
      {
        title: 'Prepaid',
        key: 'is_prepaid',
        type: 'yes_no',
        show_row: mem_details.is_prepaid === 1
      },
      {
        title: 'Prepaid next charge',
        key: 'prepaid_next_charge',
        show_row: mem_details.is_prepaid === 1 && !!mem_details.prepaid_next_charge
      },
      {
        title: 'Family Plan',
        key: 'is_student_family_package',
        type: 'yes_no',
        show_row: final_details.is_student_family_package === 1
      },
      {
        title: 'Free membership',
        key: 'is_free_membership',
        type: 'yes_no',
        show_row: final_details.is_free_membership === 1
      },
      {
        title: 'Pause status',
        key: 'membership_paused',
        show_row: details.membership_paused === 1
      },
      {
        title: 'Cancellation reason',
        key: 'cancellation_reason',
        show_row: mem_details.subscription_status === 'CANCELLED'
      },
      {
        title: 'Cancelled clicked on',
        key: 'cancellation_time',
        show_row: mem_details.subscription_status === 'CANCELLED'
      }
    ];
    return <CustomSingleTable data={final_details} data_format={membership_format} />;
  };

  render_back_pain_subscription_table = () => {
    const back_pain_detail = this.state.client_membership;

    const final_details = {
      package_name: back_pain_detail.package_name,
      last_transaction: back_pain_detail.last_transaction,
      total_sessions_in_current_billing: back_pain_detail.total_sessions_in_current_billing,
      sessions_booked_in_current_billing: back_pain_detail.sessions_booked_in_current_billing,
      sessions_used_in_current_billing: back_pain_detail.sessions_used_in_current_billing,
      sessions_remaining_in_current_billing: back_pain_detail.sessions_remaining_in_current_billing,
      next_billing_date: back_pain_detail.next_billing_date
    };
    const membership_format = [
      {
        title: 'Pack Name',
        key: 'package_name',
        show_row: true
      },
      {
        title: 'Purchase date',
        key: 'last_transaction',
        show_row: true
      },
      {
        title: 'Expiry date',
        key: 'next_billing_date',
        show_row: true
      },
      {
        title: 'Total session hours in pack',
        key: 'total_sessions_in_current_billing',
        show_row: true
      },
      {
        title: 'Sessions hours booked',
        key: 'sessions_booked_in_current_billing',
        show_row: true
      },
      {
        title: 'Sessions hours completed',
        key: 'sessions_used_in_current_billing',
        show_row: true
      },
      {
        title: 'Sessions hours remaining',
        key: 'sessions_remaining_in_current_billing',
        show_row: true
      }
    ];
    return <CustomSingleTable data={final_details} data_format={membership_format} />;
  };

  render_subscription_queue = () => {
    const details = this.state.student_details;
    if (Object.keys(details.subscription_queue).length >= 0) {
      const membership_format = [
        {
          title: 'Package Name',
          key: 'package_name',
          full_row: true
        },
        {
          title: 'Action',
          key: 'queue_type'
        },
        {
          title: 'Start Date',
          key: 'start_date_string'
        },
        {
          title: 'Package Price',
          key: 'package_price'
        }
      ];
      return (
        <>
          {Object.keys(details.subscription_queue).length === 0 ? null : (
            <>
              <div>
                <div className="mt-10">Membership Queue</div>
              </div>
              <CustomSingleTable
                data={details.subscription_queue}
                data_format={membership_format}
              />
            </>
          )}
          {details.subscription_info.is_subscribed === 1 ? (
            details.subscription_queue?.package_uuid !== '4949ba3c-3af7-11ed-a86b-dbf125e2015c' ? (
              details.subscription_info.subscribed_package_uuid ===
                'e8882c66-4d5a-4573-86de-d24864819623' &&
              details.subscription_info.subscription_status !== 'CANCELLED' ? (
                <div>
                  <button onClick={this.move_to_unlimted_pan}>
                    Move student to new unlimited plan of 2022
                  </button>
                </div>
              ) : null
            ) : null
          ) : null}
        </>
      );
    }
    return null;
  };

  render_prepaid = () => (
    <>
      <h4>Are you sure you want to change prepaid to 4 weeks membership?</h4>
      <button onClick={this.change_prepaid_to_4_weeks}>Confirm</button>
      <button onClick={this.toggle_change_prepaid}>Cancel</button>
    </>
  );

  render_membership_extension_history = () => {
    const details = this.state.student_details;
    return (
      <div style={{ marginTop: '50px' }}>
        Membership Extension History:
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col">Original Date</div>
            <div className="ct-col">Extended Date</div>
            <div className="ct-col">Update By</div>
            <div className="ct-col">Created on</div>
          </div>
          {details?.membership_extension_history?.length > 0
            ? details.membership_extension_history.map((d) => (
                <div className="ct-row" key={d.id}>
                  <div className="ct-col  ct-sm-font">
                    {moment(d.old_next_billing_date)
                      ?.tz(details.iana_timezone)
                      ?.format(day_format_year) || ''}
                  </div>
                  <div className="ct-col  ct-sm-font">
                    {moment(d.new_next_billing_date)
                      ?.tz(details.iana_timezone)
                      ?.format(day_format_year) || ''}
                  </div>
                  <div className="ct-col  ct-sm-font">
                    {d.updated_by_user_name}
                    <div>{`(${d.updated_by_user_type})`}</div>
                  </div>
                  <div className="ct-col  ct-sm-font">
                    <div>
                      {moment(d.created_date)
                        ?.tz(this.props.iana_timezone)
                        ?.format(day_format_year) || ''}
                    </div>
                    <div>
                      {moment(d.created_date)?.tz(this.props.iana_timezone)?.format(time_format) ||
                        ''}
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  };

  render_subscription_details = () => {
    const details = this.state.student_details;
    const mem_details = details.subscription_info;
    let is_member = false;
    try {
      is_member = mem_details.is_subscribed === 1;
    } catch (e) {}
    let is_cancelled = false;
    try {
      is_cancelled = mem_details.subscription_status === 'CANCELLED';
    } catch (e) {}
    const fu_details = details.subscription_queue;
    const is_fu_member = Object.keys(fu_details).length !== 0;
    const is_back_pain_member = Object.keys(this.state.client_membership).length !== 0;
    const pause_details = details.membership_pause;
    const is_pause = Object.keys(pause_details).length !== 0;
    const { pause_history } = details;
    const { pause_metrics } = details;
    const { cancellations } = details;
    const { access_type } = this.props;
    const is_fut_cancel =
      !!details.subscription_queue && details.subscription_queue.queue_type === 'CANCEL';
    if (this.state.is_student_from_bank_pain) {
      return (
        <div style={{ marginTop: '50px' }}>
          Membership:
          <div>
            {is_back_pain_member && this.state.is_student_from_bank_pain
              ? this.render_back_pain_subscription_table()
              : null}
            {this.state.is_student_from_bank_pain && details.is_backpain_purchased === 1 ? (
              <button onClick={() => this.open_cud_mem('open_mem_ext')}>Extend Membership</button>
            ) : (
              ''
            )}
            {this.state.is_student_from_bank_pain ? (
              <button onClick={() => this.open_cud_mem('open_better_back_package')}>
                Give better back package (without charging)
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      );
    }
    return (
      <div style={{ marginTop: '50px' }}>
        <div style={{ marginBottom: '50px' }}>
          <div
            className="cst-single-row cst-single-row-full"
            style={{ borderTop: '1px solid #cfcfcf', borderLeft: '1px solid #cfcfcf' }}
          >
            <div className="cst-single-td cst-first-td">Health History:</div>
            <div className="cst-single-td cst-second-td">
              {details?.health_history !== '' ? details?.health_history : 'No health history'}
            </div>
          </div>
          <div
            className="cst-single-row cst-single-row-full"
            style={{ borderLeft: '1px solid #cfcfcf' }}
          >
            <div className="cst-single-td cst-first-td">Getting to know Student better:</div>
            <div className="cst-single-td cst-second-td">
              {details?.student_additional_info !== '' ? (
                <div
                  className="gettingToKnowStyle"
                  dangerouslySetInnerHTML={{ __html: details?.student_additional_info }}
                />
              ) : (
                'No additional info'
              )}
            </div>
          </div>
          <div
            className="cst-single-row cst-single-row-full"
            style={{ borderLeft: '1px solid #cfcfcf' }}
          >
            <div className="cst-single-td cst-first-td">First 1-on-1 Trial Session Notes:</div>
            <div className="cst-single-td cst-second-td">
              <div>
                {details?.first_one_on_one_session_notes &&
                details?.first_one_on_one_session_notes.length > 0
                  ? details?.first_one_on_one_session_notes.map((item) => (
                      <>
                        {item.session_recap === '' && item.teacher_notes === '' ? (
                          'No notes entered by teacher'
                        ) : (
                          <>
                            <div style={{ whiteSpace: 'pre-wrap' }}>{item.teacher_notes}</div>
                            <br />
                            <div style={{ whiteSpace: 'pre-wrap' }}>{item.session_recap}</div>
                          </>
                        )}
                      </>
                    ))
                  : 'No 1-on-1 Session taken in trial'}
              </div>
            </div>
          </div>
        </div>
        Membership:
        {is_member || is_fu_member ? (
          <>
            {this.render_subscription_table()}
            {access_type !== 'CONSULT_CONSULTANT' && !isViewOnly(access_type) ? (
              <div>
                {is_fut_cancel ? null : mem_details.subscription_status !== 'CANCELLED' ? (
                  <button onClick={() => this.open_cud_mem('open_mem_del')}>
                    Cancel Membership
                  </button>
                ) : (
                  <button onClick={() => this.open_cud_mem('open_mem_res')}>
                    Resume Membership
                  </button>
                )}
                <button onClick={() => this.open_cud_mem('open_mem_ext')}>Extend Membership</button>
                {is_pause ? (
                  <>
                    <button onClick={() => this.pause_membership_open('Update')}>
                      Update Pause Membership
                    </button>
                    <button onClick={() => this.open_cud_mem('open_pause_delete')}>
                      Cancel Pause Membership
                    </button>
                  </>
                ) : (
                  <button onClick={() => this.pause_membership_open('Create')}>
                    Pause Membership
                  </button>
                )}
                {mem_details.is_prepaid === 1 &&
                mem_details.prepaid_change_to_four_weeks_on_renewal === 0 ? (
                  <button onClick={this.toggle_change_prepaid}>Change Prepaid to 4 Weeks</button>
                ) : null}
              </div>
            ) : null}
          </>
        ) : access_type !== 'CONSULT_CONSULTANT' &&
          !isViewOnly(access_type) &&
          !is_back_pain_member &&
          !this.state.is_student_from_bank_pain ? (
          <>
            {is_cancelled ? this.render_subscription_table() : null}
            <div>
              <button onClick={this.toggle_subscription}>Create Membership</button>
              <button onClick={this.toggle_scholarship}>Create Scholarship</button>
            </div>
          </>
        ) : null}
        {this.render_membership_extension_history()}
        {pause_metrics && details.client_domain === 'lifestyle' ? (
          <PauseMetrics pause_metrics={pause_metrics} />
        ) : null}
        {!!pause_history && pause_history.length > 0 ? (
          <div style={{ marginTop: '50px' }}>
            <div>
              Pause History: <b>The dates are displayed according to UTC time</b>
            </div>
            <div className="cus-table">
              <div className="ct-row ct-h">
                <div className="ct-col flex flex-col items-center">
                  <div className="border-b border-solid border-black w-[90px]">From</div>
                  <div>To</div>
                </div>
                <div className="ct-col">Days</div>
                <div className="ct-col ct-bw">Removed before pause started</div>
                <div className="ct-col">Processed</div>
                <div className="ct-col ct-hf">Active</div>
                <div className="ct-col">Created at</div>
                <div className="ct-col">Update By</div>
                <div className="ct-col">Reason</div>
              </div>
              {pause_history.map((p) => (
                <div className="ct-row" key={p.uuid}>
                  <div className="ct-col ct-sm-font flex flex-col items-center">
                    <div className="border-b border-solid border-black">
                      {moment(p?.from_date)?.tz(this.props.iana_timezone)?.format('YYYY-MM-DD z') ||
                        ''}
                    </div>
                    <div>
                      {moment(p?.to_date)?.tz(this.props.iana_timezone)?.format('YYYY-MM-DD z') ||
                        ''}
                    </div>
                  </div>
                  <div className="ct-col">{p?.no_of_pause_days}</div>
                  <div className="ct-col ct-bw ct-si">
                    {p.deleted_before_start === 1 ? (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                        alt="av"
                      />
                    ) : (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-delete-48.png"
                        alt="nav"
                      />
                    )}
                  </div>
                  <div className="ct-col ct-si">
                    {p.is_processed === 1 ? (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                        alt="av"
                      />
                    ) : (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-delete-48.png"
                        alt="nav"
                      />
                    )}
                  </div>
                  <div className="ct-col ct-hf ct-si">
                    {p.is_active === 1 ? (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                        alt="av"
                      />
                    ) : (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-delete-48.png"
                        alt="nav"
                      />
                    )}
                  </div>
                  <div className="ct-col ct-sm-font">
                    <div>
                      {moment(p.created_date).tz(this.props.iana_timezone).format(day_format_year)}
                    </div>
                    <div>
                      {moment(p.created_date).tz(this.props.iana_timezone).format(time_format)}
                    </div>
                  </div>
                  <div className="ct-col ct-sm-font">
                    <div>{p.updated_by_user_name}</div>
                    <div>{`(${p.updated_by_user_type})`}</div>
                  </div>
                  <div className="ct-col ct-sm-font">{p?.reason || ''}</div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {!!cancellations && cancellations.length > 0 ? (
          <div style={{ marginTop: '50px' }}>
            <div className="cus-table" style={{ marginBottom: '20px' }}>
              Cancellations:
              <div className="ct-row ct-h">
                <div className="ct-col ct-hf">By</div>
                <div className="ct-col ct-la">Primary reasons</div>
                <div className="ct-col ct-la">Secondary reasons</div>
                <div className="ct-col ct-hf">Other reason</div>
                <div className="ct-col ct-hf">Feedback</div>
                <div className="ct-col ct-qf">Date</div>
              </div>
              {cancellations.map((cancel) => (
                <div className="ct-row" key={cancel.uuid}>
                  <div className="ct-col ct-hf">
                    {cancel.cancelled_by}
                    {cancel.cancelled_by === 'care' ? (
                      <div className="ct-sm-font">({cancel.care_name})</div>
                    ) : null}
                  </div>
                  <div className="ct-col ct-la">
                    <ul className="ct-sm-font">
                      {cancel.primary_reasons.map((r) => (
                        <li>{r.cancellation_primary_reason}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="ct-col ct-la">
                    <ul className="ct-sm-font">
                      {cancel.secondary_reasons.map((r) => (
                        <li>{r.cancellation_secondary_reason}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="ct-col ct-hf ct-sm-font">{cancel.cancellation_other_reason}</div>
                  <div className="ct-col ct-hf ct-sm-font">{cancel.cancellation_feedback}</div>
                  <div className="ct-col ct-qf ct-sm-font">
                    <div>
                      {moment(cancel.created_date)
                        .tz(this.props.iana_timezone)
                        .format(day_format_year)}
                    </div>
                    <div>
                      {moment(cancel.created_date).tz(this.props.iana_timezone).format(time_format)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  render_subscription = () => {
    if (this.state.show_create_subscription) {
      if (this.state.saved_cards_list.length === 0) {
        return (
          <h3 style={{ margin: '10px 0' }}>
            No saved cards available. Please ask user to add a credit card and continue.
          </h3>
        );
      }
      return (
        <CreateSubscription
          config_values={this.props.config_values}
          student_uuid={this.state.student_uuid}
          submit_subscription={this.submit_subscription}
          submit_gift={this.submit_gift}
        />
      );
    }
    return null;
  };

  render_add_credit = () => {
    const { credit_hours, credit_mins } = this.state;
    return (
      <>
        <h4>Add Credits</h4>
        <CustomSingleSelect
          label="Select Hours"
          options={hours_list}
          onChange={this.handle_change}
          name="credit_hours"
          value={credit_hours}
          style={{ width: '300px' }}
        />
        <CustomSingleSelect
          label="Select Mins"
          options={min_list}
          onChange={this.handle_change}
          name="credit_mins"
          value={credit_mins}
          style={{ width: '300px' }}
        />
        <CustomInput
          label="Reason"
          name="creditReason"
          value={this.state.creditReason}
          onChange={this.handle_change}
        />
        <button onClick={this.add_credits}>Submit</button>
      </>
    );
  };

  render_membership_error_popup = () => {
    const { membership_error_response } = this.props;

    return (
      <div>
        <div
          className="membership-error"
          dangerouslySetInnerHTML={{ __html: membership_error_response }}
        />
        <span className="block sm:inline" />
      </div>
    );
  };

  close_member_error_popup = () => {
    this.props.set_loading_variable('show_member_error_popup', false);
    this.setState({ open_mem_del: false });
  };

  render_del_mem = () => {
    const {
      cancellation_primary_reasons,
      cancellation_secondary_reasons,
      cancellation_other_reason,
      cancellation_feedback
    } = this.state;
    let cancellation_reasons = [];
    try {
      if (!!this.props.config_values && !!this.props.config_values.cancellation_reasons)
        cancellation_reasons = this.props.config_values.cancellation_reasons;
    } catch (e) {
      console.log(e);
    }
    let btn_disabled = true;
    if (
      cancellation_primary_reasons.length > 1 ||
      (cancellation_primary_reasons.length === 1 &&
        cancellation_primary_reasons[0] !== 'Any other reason') ||
      (cancellation_primary_reasons.length === 1 &&
        cancellation_primary_reasons[0] === 'Any other reason' &&
        !!cancellation_other_reason)
    ) {
      btn_disabled = false;
    }
    return (
      <div>
        <h3>Are you sure you want to delete membership?</h3>
        {cancellation_reasons.map((reason) => {
          const reason_index = cancellation_primary_reasons.indexOf(reason.reason);
          const isItemSelected = reason_index !== -1;
          return (
            <>
              <CustomCheckbox
                custom_style={{ margin: '5px 0' }}
                label={reason.reason}
                checked={isItemSelected}
                onClick={() => this.toggle_primary_check_box(reason.reason)}
              />
              {isItemSelected && !!reason.secondary_reasons && reason.secondary_reasons.length > 0
                ? reason.secondary_reasons.map((r) => {
                    const sec_reason_index = cancellation_secondary_reasons.indexOf(r);
                    const isInnerItemSelected = sec_reason_index !== -1;
                    return (
                      <CustomCheckbox
                        custom_style={{ margin: '5px 10px' }}
                        label={r}
                        checked={isInnerItemSelected}
                        onClick={() => this.toggle_secodary_check_box(r)}
                      />
                    );
                  })
                : null}
              {isItemSelected && reason.reason === 'Any other reason' ? (
                <div>
                  <textarea
                    value={cancellation_other_reason}
                    style={{ width: '300px', height: '60px' }}
                    onChange={this.onCommonChange}
                    name="cancellation_other_reason"
                  />
                </div>
              ) : null}
            </>
          );
        })}
        <p style={{ marginTop: '15px' }}>FeedBack</p>
        <div>
          <textarea
            value={cancellation_feedback}
            style={{ width: '300px', height: '60px' }}
            onChange={this.onCommonChange}
            name="cancellation_feedback"
          />
        </div>
        <Button onClick={this.delete_membership} disabled={btn_disabled}>
          Confirm
        </Button>
      </div>
    );
  };

  render_mem_resume = () => {
    const data = {
      type: 'resume',
      payload: { student_uuid: this.state.student_details.student_uuid }
    };
    return (
      <div>
        <h3>Are you sure you want to resume membership?</h3>
        <button onClick={() => this.props.cud_membership(data)}>Confirm</button>
      </div>
    );
  };

  render_delete_pause = () => {
    try {
      const data = {
        type: 'delete',
        payload: {
          student_uuid: this.state.student_details.student_uuid,
          membership_pause_uuid: this.state.student_details.membership_pause.uuid,
          reason: this.state.membership_pause_reason
        }
      };
      return (
        <div>
          <h3>Are you sure you want to cancel membership pause?</h3>
          <div>
            <div className="custom-date-picker-label">Reason:</div>
            <textarea
              value={this.state.membership_pause_reason}
              style={{ width: '300px', height: '60px' }}
              onChange={this.onCommonChange}
              name="membership_pause_reason"
            />
          </div>
          <button onClick={() => this.props.cud_membership_pause(data)}>Confirm</button>
        </div>
      );
    } catch (e) {
      return null;
    }
  };

  render_create_pause = () => {
    const data = {
      type: this.state.membership_pause_type.toLowerCase(),
      payload: {
        student_uuid: this.state.student_details.student_uuid,
        from_date: `${this.state.membership_start_date}`,
        to_date: `${this.state.membership_end_date}`,
        reason: this.state.membership_pause_reason
      }
    };

    if (this.state.membership_pause_type === 'Update') {
      data.payload.membership_pause_uuid = this.state.student_details.membership_pause.uuid;
    }
    return (
      <div style={{ minHeight: '600px' }}>
        <h2
          className={`mb4 ${this.state.membership_pause_type === 'Create' ? 'capitalize mb-4 font-semibold' : 'text-center'}`}
        >
          {this.state.membership_pause_type} membership pause
        </h2>
        <div className="flex items-center gap-2">
          <CustomDatePicker
            label="Pause Start Date"
            name="membership_start_date"
            value={this.state.membership_start_date}
            handleChange={(val) => {
              this.handle_date_change('membership_start_date', val);
            }}
            minDate={moment().format('YYYY-MM-DD')}
            maxDate={moment(this.state.membership_end_date).format('YYYY-MM-DD')}
          />
          <CustomDatePicker
            label="Pause End Date"
            name="membership_end_date"
            value={this.state.membership_end_date}
            handleChange={(val) => {
              this.handle_date_change('membership_end_date', val);
            }}
            minDate={moment(this.state.membership_start_date).format('YYYY-MM-DD')}
          />
        </div>
        <div>
          <div className="custom-date-picker-label">Reason:</div>
          <textarea
            value={this.state.membership_pause_reason}
            style={{ width: '300px', height: '60px' }}
            onChange={this.onCommonChange}
            name="membership_pause_reason"
          />
        </div>
        <div className="mt-5">
          <Button
            onClick={() => {
              this.props.cud_membership_pause(data);
              this.setState({ shouldFetchData: true, show_pause_error: true });
            }}
            disabled={!this.state.membership_start_date || !this.state.membership_end_date}
          >
            Submit
          </Button>
        </div>
        <VacationPauseMembershipDetails
          from_date={this.state.membership_start_date}
          to_date={this.state.membership_end_date}
          student_uuid={this.state.student_details.student_uuid}
          shouldFetchData={this.state.shouldFetchData}
          onSessionListEmpty={this.handleSessionListEmpty}
        />

        {this.state.show_pause_error ? (
          <>
            <hr />
            <div>
              <div
                className="membership-error"
                dangerouslySetInnerHTML={{ __html: this.props.pause_error_response }}
              />
            </div>
          </>
        ) : null}
      </div>
    );
  };

  render_add_roadmap = () => {
    const { student_details, new_gender, new_age } = this.state;
    let roadmap_questions = [];
    if (student_details.roadmap_questions) {
      roadmap_questions = student_details.roadmap_questions;
    }
    return (
      <div>
        Add roadmap
        {roadmap_questions.map((ques, index) => (
          <div key={ques.id + index}>
            <div style={{ marginTop: '20px' }}>{`${index + 1}. ${ques.question}`}</div>
            <textarea
              value={ques.answer ? ques.answer : ''}
              style={{ width: '300px', height: '60px' }}
              onChange={(e) => this.on_roadmap_change(index, e.target.value)}
            />
          </div>
        ))}
        <CustomInput
          label="Enter Age"
          value={new_age}
          name="new_age"
          onChange={this.handle_number_change_new}
          type="number"
        />
        <CustomSingleSelect
          label="Select Gender"
          // options={this.state.subs_select_values}
          options={genders}
          onChange={this.onCommonChange}
          name="new_gender"
          value={new_gender}
          style={{ width: '300px' }}
        />
        <button onClick={this.save_roadmap}>Save</button>
      </div>
    );
  };

  render_create_roadmap = () => {
    let future_sessions = [];
    const { student_details, future_sess_uuid } = this.state;
    try {
      future_sessions = student_details.future_sessions || [];
    } catch {}
    const fut_sess_select_vals = [];
    for (const sess of future_sessions) {
      fut_sess_select_vals.push({
        label: `Session with ${sess.teacher_name} at ${moment(sess.start_time)
          .tz(this.props.iana_timezone)
          .format('MMM DD hh:mm A z')}`,
        value: sess.session_uuid
      });
    }
    return (
      <div>
        <h4>Are you sure you want to create roadmap?</h4>
        <Button onClick={this.create_roadmap}>Submit</Button>
      </div>
    );
  };

  render_confirm_affiliate = () => (
    <>
      <div style={{ margin: '15px 0' }}>Are you sure you want to add onboard affiliate? </div>
      <Button onClick={this.add_affiliate}>Confirm</Button>
      <Button type="secondary" onClick={this.close_cud_mem}>
        Cancel
      </Button>
    </>
  );

  remove_from_family_member = () => {
    this.setState({ loading: true }, () => {
      const { remove_family_member_uuid, remove_reason } = this.state;
      const url = get_api_url('/v2/payments/cms/update_from_family', null, true);
      const payload = {
        student_uuid: remove_family_member_uuid,
        reason: remove_reason,
        is_active: 0,
        by_cms: 1
      };
      api_with_method('put', url, payload, true)
        .then((res) => {
          if (res.status === 200) {
            this.setState(
              {
                remove_family_member_popup: false,
                open_family_plan: true,
                loading: false,
                remove_reason: ''
              },
              () => {
                this.load_family_member_list();
              }
            );
          }
        })
        .catch((e) => {
          console.log('Error in remove family member', e);
          this.setState({
            loading: false
          });
        });
    });
  };

  open_remove_member_popup = (uuid, email) => {
    this.setState(
      {
        remove_family_member_popup: true,
        remove_family_member_uuid: uuid,
        removed_user_email: email
      },
      () => {
        this.setState({ open_family_plan: false });
      }
    );
  };

  render_remove_femaily_member = () => {
    const { remove_reason, removed_user_email } = this.state;

    return (
      <>
        <h2 className="text-center mb-4 text-xl">Remove User {removed_user_email} </h2>
        <div className="flex">
          <textarea
            value={remove_reason}
            name="remove_reason"
            onChange={this.onCommonChange}
            placeholder="Please enter the reason"
          />
          <Button disabled={remove_reason === ''} onClick={this.remove_from_family_member}>
            Submit
          </Button>
        </div>
      </>
    );
  };

  render_add_student_to_family_member = () => {
    const { student_email, selected_student_email_uuid, family_member_list, searched_email_list } =
      this.state;
    let is_student_already_member = false;

    is_student_already_member = family_member_list.some(
      (student) =>
        (student.email === student_email && student.is_active === 1) ||
        (student.uuid === selected_student_email_uuid && student.is_active === 1)
    );

    return (
      <>
        <div className="text-center mb-4 text-xl">Add Student to Family Group</div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <div>
              <CustomInput
                label="Enter student name or email"
                value={student_email}
                onChange={this.onCommonChange}
                name="student_email"
                onKeyPress={this.on_key_press}
              />
            </div>
            <div>
              <Button
                onClick={() => this.on_search_click()}
                disabled={student_email.length < 3 || is_student_already_member}
              >
                Search Student
              </Button>
            </div>
          </div>
          <div>
            {is_student_already_member && student_email !== '' ? (
              <div className="mt-4 mb-4 text-mytRed">
                Student is already present of current family{' '}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex items-center">
          {!!student_email && this.props.student_search_status === 'success' ? (
            <div className="flex items-center mt-4 gap-4">
              <div>
                <CustomSingleSelect
                  label="Select Student"
                  options={searched_email_list}
                  onChange={this.onCommonChange}
                  name="selected_student_email_uuid"
                  value={selected_student_email_uuid}
                />
              </div>
              <div>
                <Button
                  disabled={!selected_student_email_uuid || is_student_already_member}
                  onClick={this.add_student_to_family_group}
                >
                  Add Student
                </Button>
              </div>
            </div>
          ) : null}
        </div>

        {!!family_member_list && (
          <div>
            <table className="relative w-full border">
              <thead>
                <tr className="text-xs">
                  <th className="sticky top-0 text-white bg-mytBlue">Email</th>
                  <th className="sticky top-0 text-white bg-mytBlue">Family Member</th>
                  <th className="sticky top-0 text-white bg-mytBlue">Active Membership</th>
                  <th className="sticky top-0 text-white bg-mytBlue" />
                  <th className="sticky top-0 text-white bg-mytBlue">Removed Date</th>
                  <th className="sticky top-0 text-white bg-mytBlue">Reason</th>
                </tr>
              </thead>
              <tbody>
                {family_member_list?.map((item, index) => (
                  <tr key={index} className="text-xs">
                    <td>{item?.email}</td>
                    <td>{item?.is_active === 1 ? 'Yes' : 'No'}</td>
                    <td>{item?.is_member === 1 ? 'Yes' : 'No'}</td>
                    <td>
                      {item?.is_active === 1 ? (
                        <Button
                          onClick={() => this.open_remove_member_popup(item?.uuid, item?.email)}
                        >
                          Remove
                        </Button>
                      ) : (
                        <Button disabled>Removed</Button>
                      )}
                    </td>
                    <td>
                      {item?.removed_date === ''
                        ? ''
                        : moment(item?.removed_date)
                            .tz(this.props.iana_timezone)
                            .format(day_format_year)}
                    </td>
                    <td>{item?.reason}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  };

  add_student_to_family_group = () => {
    this.setState({ loading: true }, () => {
      const { student_uuid, selected_student_email_uuid } = this.state;
      const url = get_api_url('/v2/payments/cms/add_student_to_family', null, true);
      const payload = {
        student_uuid,
        add_to_student_uuid: selected_student_email_uuid
      };
      api_with_method('post', url, payload, true)
        .then((res) => {
          if (res.status === 200) {
            this.setState(
              {
                loading: false,
                selected_student_email_uuid: '',
                student_email: '',
                searched_email_list: []
              },
              () => {
                this.load_family_member_list();
              }
            );
          }
        })
        .catch((e) => {
          this.setState({
            loading: false
          });
        });
    });
  };

  render_scholarship = () => (
    <div>
      <h4>Are you sure you want to create scholarship</h4>
      <p>Name: Unlimited group classes, Zero 1-on-1 sessions</p>
      <p>Price: $29</p>
      <button onClick={this.add_scholarship}>Confirm</button>
      <button onClick={this.toggle_scholarship_next}>Back</button>
    </div>
  );

  render_add_referral = () => {
    const { student_search_text, add_student_uuid } = this.state;
    const student_select_values = [];
    this.props.search_students_list.map((st) => {
      const { student_uuid } = st;
      if (student_uuid) {
        const fname = st.sq_fname ? st.sq_fname : '';
        const mname = st.sq_mname ? st.sq_mname : '';
        const lname = st.sq_lname ? st.sq_lname : '';
        student_select_values.push({
          label: `${fname} ${mname} ${lname} (${st.email})`,
          value: student_uuid
        });
      }
    });
    return (
      <div>
        <h3>Add a referral of the student</h3>
        <div className="display-flex-between-center">
          <CustomInput
            label="Enter student name or email"
            onChange={this.onCommonChange}
            name="student_search_text"
            value={student_search_text}
            style={{ width: '300px' }}
            onKeyPress={this.on_key_press}
          />
          <Button disabled={student_search_text.length < 3} onClick={this.on_search_click}>
            Search student
          </Button>
        </div>
        {!!student_search_text && this.props.student_search_status === 'success' ? (
          <div>
            <CustomSingleSelect
              label="Select student"
              options={student_select_values}
              onChange={this.onCommonChange}
              name="add_student_uuid"
              value={add_student_uuid}
              style={{ width: '250px' }}
            />
            <Button disabled={!add_student_uuid} onClick={this.add_referral}>
              Add referrer
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  render_raw_data = () => {
    const { student_details, show_raw_data, raw_data_search } = this.state;
    if (show_raw_data) {
      const { raw_data } = student_details;
      const object_keys = Object.keys(raw_data).sort();
      const filtered_keys = object_keys.filter(
        (k) => k.toLowerCase().indexOf(raw_data_search.toLowerCase()) !== -1
      );
      return (
        <div style={{ height: '80vh' }}>
          <CustomInput
            label="Search key value"
            style={{ width: '500px' }}
            value={raw_data_search}
            name="raw_data_search"
            onChange={this.onCommonChange}
          />
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col ct-hf ct-la ct-pl-10">Key</div>
              <div className="ct-col ct-la ct-pl-10">Value</div>
            </div>
            {filtered_keys.map((key_val, index) => {
              const val = raw_data[key_val];
              if (typeof val !== 'object') {
                return (
                  <div className="ct-row" key={index + key_val}>
                    <div className="ct-col ct-hf ct-bw ct-sm-font ct-la ct-pl-10">
                      <HighlightSearch full_text={key_val} search_text={raw_data_search} />
                    </div>
                    <div className="ct-col ct-bw ct-sm-font ct-la ct-pl-10">{val}</div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  render_unban = () => (
    <div>
      <h4>Are you sure you want to remove ban?</h4>
      <button onClick={this.unban_user}>Confirm</button>
    </div>
  );

  render_ban = () => {
    const { ban_reason } = this.state;
    return (
      <div>
        <h4>Are you sure you want to ban user?</h4>
        <CustomInput
          label="Enter reason"
          style={{ width: '500px' }}
          value={ban_reason}
          name="ban_reason"
          onChange={this.onCommonChange}
        />
        <Button disabled={!ban_reason} onClick={this.ban_user}>
          Submit
        </Button>
      </div>
    );
  };

  render_same_students = () => {
    const { student_details } = this.state;
    if (student_details?.same_students?.length > 0) {
      return (
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h">
            <div className="ct-col">Other Student</div>
            <div className="ct-col">Same Ip address</div>
            <div className="ct-col">Same Device</div>
            <div className="ct-col">Same Phone</div>
            <div className="ct-col">Banned</div>
          </div>
          {student_details.same_students.map((st) => {
            const student_url = `/studentprofile?id=${st.uuid}`;
            return (
              <div className="ct-row" key={st.uuid}>
                <div className="ct-col">
                  <a className="link-no-dec" href={student_url}>
                    <div className="profile-img-name">
                      <img src={st.profile_photo} onError={image_error_replace} alt="x" />
                      {`${st.first_name} ${st.last_name}`}
                    </div>
                  </a>
                </div>
                <div className="ct-col ct-si">
                  {st.same_ip === 1 ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
                <div className="ct-col ct-si">
                  {st.same_device === 1 ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
                <div className="ct-col ct-si">
                  {st.same_phone === 1 ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
                <div className="ct-col ct-si">
                  {st.is_banned === 1 ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  render_reset_trial = () => (
    <div>
      <h4>Are you sure you want to reset trial period?</h4>
      <Button onClick={this.confirm_reset_trial}>Confirm</Button>
      <Button type="secondary" onClick={this.toggle_reset_trial}>
        Cancel
      </Button>
    </div>
  );

  render_change_email = () => {
    if (this.state.change_email_confirm) {
      return (
        <div>
          <h4>Are you sure you want to change email to {this.state.change_email}</h4>
          <Button onClick={this.confirm_change_email}>Confirm</Button>
          <Button type="secondary" onClick={this.toggle_change_email}>
            Cancel
          </Button>
        </div>
      );
    }
    return (
      <div>
        <h4>Enter new email to change</h4>
        <CustomInput
          label="New Email"
          name="change_email"
          value={this.state.change_email}
          onChange={this.handle_change}
        />
        <Button
          disabled={!this.state.change_email}
          onClick={() => this.setState({ change_email_confirm: true })}
        >
          Confirm
        </Button>
      </div>
    );
  };

  render_single_notes = (notes) => (
    <div key={notes.uuid} className="notes-text">
      <div className="notes-text-auth">{`${notes.name} (${moment(notes.created_date)
        .tz(this.props.iana_timezone)
        .format(time_day_format)})`}</div>
      <div className="notes-text-val">{notes.notes}</div>
    </div>
  );

  render_notes = () => {
    const { new_notes, notes } = this.state;
    return (
      <div className="notes-container">
        <h4>Notes on Student</h4>
        <div className="notes-messages">
          {notes?.map(this.render_single_notes)}
          <div style={{ float: 'left', clear: 'both' }} ref={this.notesRef} />
        </div>
        <div className="notes-input">
          <textarea
            value={new_notes}
            onChange={this.onCommonChange}
            placeholder="Enter notes..."
            name="new_notes"
          />
          <Button onClick={this.save_notes}>Save</Button>
        </div>
      </div>
    );
  };

  render_unsubscribe = () => (
    <div>
      <h4>Are you sure you want to unsubscribe?</h4>
      <button onClick={this.unsubscribe_confirm}>Confirm</button>
      <button onClick={this.close_cud_mem}>Cancel</button>
    </div>
  );

  render_concierge = () => {
    const { concierge, concierge_list } = this.state;
    return (
      <div className="flex items-center flex-col justify-center ">
        <CustomSingleSelect
          label="Select concierge to change"
          options={concierge_list}
          name="concierge"
          onChange={this.onCommonChange}
          value={concierge}
          style={{ width: '250px' }}
        />
        <div>
          <button onClick={this.save_concierge}>Submit</button>
        </div>
      </div>
    );
  };

  render_extend_trial_period_popup = () => {
    const { extend_trial_date } = this.state;
    const current_date = new Date();
    const trial_end_date = new Date(extend_trial_date);
    const extend_trial_end_date =
      trial_end_date.getTime() > current_date.getTime()
        ? extend_trial_date
        : current_date.toISOString().split('T')[0];

    return (
      <div className="flex flex-col items-center justify-center mb-80">
        <div className="mb-5 font-bold">
          Extend Trial Period of {this.state.student_details.first_name}
        </div>
        <div className="mr-5">
          <CustomDatePicker
            label="*Select Date"
            placeholder="select date"
            value={extend_trial_end_date}
            name="extend_trial_date"
            minDate={new Date()}
            handleChange={(val) => this.handle_date_change('extend_trial_date', val)}
          />
        </div>
        <button onClick={this.save_extend_trial_period_date}>Save</button>
      </div>
    );
  };

  render_better_back_package = () => {
    const { error_msg, show_card_error } = this.state;
    return (
      <>
        <div>Are you sure you want to Give better back package (without charging) </div>
        <div>
          <button onClick={this.charge_user_for_better_back_pacakge}>Confirm</button>
          <button onClick={this.close_cud_mem}>Cancel</button>
        </div>
        {show_card_error && <div className="text-red-600">{error_msg}</div>}
      </>
    );
  };

  charge_user_for_better_back_pacakge = () => {
    const { student_uuid } = this.state;
    const url = get_api_url('add_free_subscription_backpain_client_cms');
    const payload = {
      student_uuid,
      package_uuid: 'be5d2699-fe29-11ed-8de0-12307e943391'
    };
    this.setState({ loading: true });
    post_api(url, payload, true)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ open_better_back_package: false, loading: false }, () => {
            this.load_data();
          });
        }
      })
      .catch((e) => {
        this.setState({
          show_card_error: true,
          error_msg: e.response.data.message,
          loading: false
        });
      });
  };

  render_extend_membership = () => {
    const {
      confirm_extension,
      nextBillingDate,
      open_mem_ext,
      is_student_from_bank_pain,
      show_extend_membership_error,
      extend_membership_error_msg
    } = this.state;
    if (open_mem_ext) {
      const subscription_info = this.state.student_details?.subscription_info;
      const back_pain_detail = this.state.client_membership;
      const converted_date = moment(back_pain_detail.next_billing_date, 'MMM DD, YYYY').format(
        'ddd, DD MMM YYYY'
      );

      const renew_date = is_student_from_bank_pain
        ? converted_date
        : subscription_info.my_account_membership?.membership_renew_date;
      const moment_renew = moment(renew_date, 'ddd, D MMM YYYY').add(1, 'days');
      if (confirm_extension) {
        return (
          <div>
            Are you sure you want to change the date to{' '}
            {moment(nextBillingDate).format('MMMM D, yyyy')}
            <div>
              <button onClick={this.extend_membership}>Confirm</button>
              <button onClick={() => this.setState({ confirm_extension: false })}>Cancel</button>
            </div>
          </div>
        );
      }
      return (
        <div style={{ height: '500px' }}>
          <h4>Please select date to extend membership:</h4>
          <DatePicker
            selected={nextBillingDate}
            onChange={(val) => this.handle_date_change('nextBillingDate', val)}
            minDate={moment_renew.toDate()}
            dateFormat="MMMM d, yyyy"
          />
          <Button
            onClick={() => this.setState({ confirm_extension: true })}
            disabled={!nextBillingDate}
          >
            Confirm
          </Button>
          {show_extend_membership_error ? (
            <div
              className="membership-error"
              dangerouslySetInnerHTML={{ __html: extend_membership_error_msg }}
            />
          ) : (
            ''
          )}
        </div>
      );
    }
  };

  show_cosult_form = () => {
    this.setState({ show_consult_form: true });
  };

  close_consult_from = () => {
    this.setState({ show_consult_form: false });
  };

  render_consult_form = () => {
    const details = this.state.student_details?.post_cosultation_forms;

    return (
      <table className="relative w-full border">
        <thead>
          <tr>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Observations</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Created</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Modified</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Follow up</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Purchase Interested</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Weakness</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Weakness area</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Pain</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Session</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Student</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Therapy recommended</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Time preference</th>
          </tr>
        </thead>
        <tbody>
          {details?.map((form, index) => (
            <tr key={index}>
              <td>{form.additional_observations}</td>
              <td>{moment(form.created_date).tz(this.props.iana_timezone).format(day_format)}</td>
              <td>{moment(form.modified_date).tz(this.props.iana_timezone).format(day_format)}</td>
              <td>{form.is_follow_up_required}</td>
              <td>{form.is_therapy_program_purchase_interested}</td>
              <td>{form.is_weakness_effected}</td>
              <td>{form.weakness_effected_areas}</td>
              <td>{form.pain_triggers}</td>
              <td>{form.session_id}</td>
              <td>{form.student_id}</td>
              <td>{form.therapy_program_recommended}</td>
              <td>{form.therapy_session_time_preference}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  _render_consult_form = () => {
    const details = this.state.student_details?.post_cosultation_forms[0];
    return (
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 myt-table">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Questions{' '}
              </th>
              <th scope="col" className="px-6 py-3">
                Answers
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900">
                Has experienced any numbness, tingling, or weakness in legs, feet, or toes, If yes,
                which specific areas are affected
              </th>
              <td className="px-6 py-4">
                {details?.is_weakness_effected === 0 ? 'No ' : 'Yes '},{' '}
                {details?.weakness_effected_areas}
              </td>
            </tr>
            <tr className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900">
                Did client notice any factors that seem to trigger or exacerbate the pain
              </th>
              <td className="px-6 py-4">{details?.pain_triggers}</td>
            </tr>
            <tr className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900">
                Any additional observations or pertinent information regarding the client's medical
                condition to pass on to the therapist
              </th>
              <td className="px-6 py-4">{details?.additional_observations}</td>
            </tr>

            <tr className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900">
                Do you recommend reffering the client to back pain relief program
              </th>
              <td className="px-6 py-4">
                {details?.therapy_program_recommended === undefined
                  ? '--'
                  : details?.therapy_program_recommended === 1
                    ? 'Yes'
                    : 'No'}
              </td>
            </tr>
            <tr className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900">
                Did the client express interest in purchasing the Back Pain relief pack
              </th>
              <td className="px-6 py-4">
                {details?.is_therapy_program_purchase_interested === undefined
                  ? '--'
                  : details?.is_therapy_program_purchase_interested === 1
                    ? 'Yes'
                    : 'No'}
              </td>
            </tr>
            <tr className="bg-white border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900">
                Client preffered days and times
              </th>
              <td className="px-6 py-4">{details?.therapy_session_time_preference}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  show_upload_medical_popup = () => {
    this.setState({ show_upload_medical: true });
  };

  show_know_student_better_popup = () => {
    this.setState((prevState) => ({
      is_know_student_better_open: !prevState.is_know_student_better_open
    }));
  };

  show_capture_activity_level_popup = () => {
    this.setState((prevState) => ({
      is_capture_activity_level: !prevState.is_capture_activity_level
    }));
  };

  close_upload_medical_popup = () => this.setState({ show_upload_medical: false });

  render_upload_medical_popup = () => {
    const details = this.state.student_details;

    return (
      <FileComponent
        uploadService={uploadDiagnosisReport}
        student_uuid={this.state.student_uuid}
        close_upload_medical_popup={this.close_upload_medical_popup}
        load_data={this.load_data}
        clientDomain={details.client_domain}
      />
    );
  };

  render_know_student_better = () => (
    <GettingToKnowStudentBetter data={this.state.student_details} />
  );

  render_profile_buttons_new = () => {
    const { student_details, is_student_from_bank_pain, client_domain } = this.state;
    const { access_type } = this.props;
    const is_not_consultant = access_type !== 'CONSULT_CONSULTANT';
    let zendesk_href = '';
    if (!!student_details.zendesk_id && student_details.zendesk_id) {
      zendesk_href = `https://mytcare.zendesk.com/agent/users/${student_details.zendesk_id}/requested_tickets`;
    }
    return (
      <div>
        <div className="spc">
          <div className="spc-btn-co">
            <div className="spc-profile">
              <div className="teacher-profile-image">
                <img
                  src={student_details.profile_photo}
                  alt="profile_pic"
                  onError={image_error_replace}
                />
              </div>
              <h4>
                {student_details.first_name} {student_details.last_name}
              </h4>
            </div>
            {!isViewOnly(access_type) ? (
              <div className="spc-btn-in-co">
                {access_type !== 'CONSULT_CONSULTANT' ? (
                  <div className="spc-profile-change" onClick={this.update_pic}>
                    Change Photo
                    <input
                      type="file"
                      id="pic-input"
                      onChange={this.handleFileChange}
                      name="photo"
                    />
                  </div>
                ) : null}
                <button onClick={this.impersonate_teacher}>Impersonate</button>
              </div>
            ) : null}
          </div>
          {/* {is_not_consultant ?  : null} */}
          <div className="spc-btn-co">
            <p>Profile:</p>
            <div className="spc-btn-in-co">
              {!isViewOnly(access_type) ? (
                <>
                  {is_not_consultant ? (
                    <button onClick={this.toggle_edit_profile}>Edit profile</button>
                  ) : null}
                  <button onClick={this.toggle_change_email}>Change Email</button>
                  {access_type !== '' && !!zendesk_href ? (
                    <a href={zendesk_href} target="_blank" rel="noopener noreferrer">
                      Zendesk Profile
                    </a>
                  ) : null}
                  {is_not_consultant && student_details.is_banned === 0 ? (
                    <button onClick={this.toggle_ban}>Ban User</button>
                  ) : null}
                  {is_not_consultant && student_details.is_banned === 1 ? (
                    <button onClick={this.toggle_unban}>Remove Ban</button>
                  ) : null}
                  {is_not_consultant && student_details?.same_students?.length > 0 ? (
                    <button onClick={this.toggle_same_students}>Same Students</button>
                  ) : null}
                  <button onClick={this.show_upload_medical_popup}>Upload Medical Document</button>
                  <button onClick={this.show_know_student_better_popup}>
                    Getting to know Student better
                  </button>
                  <button onClick={this.show_capture_activity_level_popup}>
                    Capture Activity Level
                  </button>
                  {client_domain === 'backpain' && (
                    <button onClick={this.toggle_back_pain_area_popup}>Pain Area </button>
                  )}
                </>
              ) : null}
            </div>
          </div>
          <div className="spc-btn-co">
            <div>
              <p>Contact:</p>
              <div className="spc-btn-in-co">
                {!isViewOnly(access_type) ? (
                  <>
                    {is_student_from_bank_pain ? null : (
                      <button onClick={this.openChatScreen}>Chat</button>
                    )}
                    <button onClick={this.send_email}>Send Email</button>
                    {(!!student_details.phone_personal && student_details.phone_personal != '') ||
                    (!!student_details.phone_no && student_details.phone_no != '') ? (
                      <button onClick={this.open_sms_window}>Send SMS</button>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
            <div>
              <p>Membership:</p>
              <div className="spc-btn-in-co">
                {!isViewOnly(access_type) ? (
                  <>
                    {is_not_consultant ? (
                      <button onClick={this.open_transactions}>Transactions</button>
                    ) : null}
                    <button onClick={this.open_add_credit}>Add Credit</button>
                    {is_not_consultant ? (
                      <button onClick={this.open_credit_history}>Credit History</button>
                    ) : null}
                    {is_not_consultant ? (
                      <button onClick={this.open_family_plan}>Add student to family plan</button>
                    ) : null}
                    {is_not_consultant ? (
                      <button onClick={this.openAddCreditForMembership}>
                        Give Amount Credits for Membership Renewal
                      </button>
                    ) : null}
                    {is_not_consultant ? (
                      <button onClick={this.openMembershipCreditHistory}>
                        Membership Credit History
                      </button>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="spc-btn-co">
            <p>Sessions:</p>
            <div className="spc-btn-in-co">
              <button onClick={this.toggle_notes}>Notes</button>
              <button onClick={this.open_student_sessions}>Sessions list</button>
              {!isViewOnly(access_type) ? (
                <>
                  {is_not_consultant ? (
                    <button onClick={this.open_book_session}>Book Session</button>
                  ) : null}
                  {student_details.show_roadmap_button === 1 ? (
                    <button onClick={this.open_add_roadmap}>Edit Roadmap</button>
                  ) : (
                    <button onClick={this.open_create_roadmap}>Create Roadmap</button>
                  )}
                </>
              ) : null}
              {is_student_from_bank_pain ? (
                <button onClick={this.show_cosult_form}>Consult Form</button>
              ) : null}
              {is_student_from_bank_pain ? (
                <> </>
              ) : (
                <button onClick={this.toggle_easy_filter_popup}>Easy Filters</button>
              )}
              {is_student_from_bank_pain ? (
                <> </>
              ) : (
                <button onClick={this.toggle_easy_filter_history_popup}>
                  Easy Filters History
                </button>
              )}
            </div>
          </div>
          <div className="spc-btn-co">
            <p>Marketing:</p>
            <div className="spc-btn-in-co">
              {is_not_consultant ? (
                <button onClick={this.open_email_logs}>Email Logs</button>
              ) : null}
              {is_not_consultant ? (
                <button onClick={this.open_notifications}>Notifications</button>
              ) : null}
              {!isViewOnly(access_type) ? (
                <>
                  {is_not_consultant ? (
                    <button onClick={this.open_notification_preferences}>
                      Notification Preferences
                    </button>
                  ) : null}
                  <a
                    className="link-btn"
                    href={`/user_timeline/student/${student_details.student_uuid}`}
                  >
                    Timelines Events
                  </a>
                  {student_details.unsubscribe_from_all_lists === 0 ? (
                    <button onClick={this.unsubscribe_from_all_lists}>
                      Unsubscribe from marketing emails
                    </button>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
          <div className="spc-btn-co">
            <p>Other:</p>
            <div className="spc-btn-in-co">
              {is_not_consultant ? <button onClick={this.toggle_raw_data}>Raw Data</button> : null}
              {!isViewOnly(access_type) ? (
                <>
                  {is_not_consultant ? (
                    <button onClick={this.open_affliate}>Add Affiliate</button>
                  ) : null}
                  {is_not_consultant ? (
                    <button onClick={this.add_student_referral}>Add referrer</button>
                  ) : null}
                  {/* {is_not_consultant ?  : null} */}
                  <button onClick={this.open_extend_trial_modal}>Extend Trial Period</button>
                  <button onClick={this.toggle_reset_trial}>Reset Trial</button>
                  {is_not_consultant ? (
                    <button onClick={this.student_tests}>Split Tests</button>
                  ) : null}
                  <button onClick={this.recommendationsPlayground}>
                    Recommendations Playground
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {access_type === 'SUPER_USER' ? (
          <div className="flex flex-col justify-center item-center w-full border-t-4 border-indigo-500">
            <div className="text-center text-xl font-bold">Super User</div>
            <div className="flex item-center justify-center">
              <div className="text-center">
                <button onClick={this.open_concierge_modal}>
                  {this.change_concierge_btn_name()}
                </button>
              </div>
            </div>
          </div>
        ) : (
          this.render_student_conierge()
        )}
      </div>
    );
  };

  search_location = (location) =>
    this.state?.student_details?.onboarding_survey?.back_pain_location?.includes(location);

  render_back_pain_area = () => {
    const { student_details } = this.state;
    if (isEmpty(student_details?.onboarding_survey?.back_pain_location)) {
      return (
        <div className="flex text-center justify-center item-center gap-25px relative min-h-325px">
          No data found
        </div>
      );
    }
    const updated_back_pain_location = student_details?.onboarding_survey?.back_pain_location;
    const back_pain_location = convertToSentenceCase(
      updated_back_pain_location?.replaceAll('_', ' ')
    );
    return (
      <div className="flex justify-between gap-25px relative min-h-325px">
        <div className="pt-15px">
          <AboutMeText text="Pain Area" classnames="font-bold" />
          <div className="font-normal mt-0.5 mb-10 text-wrap w-160px text-xs leading-15px text-black">
            <AboutMeText
              text={back_pain_location}
              classnames="font-normal mt-0.5 mb-10 text-wrap w-108px"
            />
          </div>
        </div>
        <HumanSkeletonDummy
          neck={this.search_location('neck')}
          leftShoulder={this.search_location('left_shoulder')}
          rightShoulder={this.search_location('right_shoulder')}
          leftKnee={this.search_location('left_knee')}
          rightKnee={this.search_location('right_knee')}
          midBack={this.search_location('mid_back')}
          leftLeg={this.search_location('left_leg')}
          rightLeg={this.search_location('right_leg')}
          rightHip={this.search_location('right_hip')}
          leftHip={this.search_location('left_hip')}
          rightLowerBack={this.search_location('right_lower_back')}
          leftLowerBack={this.search_location('left_lower_back')}
          midLowerBack={this.search_location('mid_lower_back')}
          bodyPartsLength={student_details?.onboarding_survey?.back_pain_location.length > 0}
        />
      </div>
    );
  };

  render_profile_buttons = () => {
    const { student_details } = this.state;

    const { access_type } = this.props;

    return (
      <div className="teacher-profile-content flex flex-col">
        <div className="teacher-profile-links">
          <div className="teacher-profile-image-container">
            <div className="teacher-profile-image">
              <img
                src={student_details.profile_photo}
                alt="profile_pic"
                onError={image_error_replace}
              />
              {access_type !== 'CONSULT_CONSULTANT' ? (
                <div className="teacher-profile-image-change" onClick={this.update_pic}>
                  Change Photo
                  <input type="file" id="pic-input" onChange={this.handleFileChange} name="photo" />
                </div>
              ) : null}
            </div>
            <h4>
              {student_details.first_name} {student_details.last_name}
            </h4>
          </div>
          <div className="teacher-profile-links-container" />
        </div>
      </div>
    );
  };

  openAddCreditForMembership = () => this.setState({ showAddCreditPopup: true });

  render() {
    const {
      aff_loading,
      loading,
      book_session,
      student_uuid,
      student_details,
      edit_profile,
      scholarship_open,
      add_student_referral,
      z_index_loading,
      ban_open,
      unban_open,
      sms_window_open,
      sms_window_student,
      unsubscribe_open,
      show_consult_form,
      notes_open,
      open_change_prepaid,
      show_concierge_modal,
      extend_trial_period_poup,
      open_mem_ext,
      client_domain,
      show_upload_medical,
      show_back_pain_area,
      open_better_back_package,
      show_easy_filter_popup,
      show_easy_filter_history_popup,
      is_know_student_better_open,
      is_capture_activity_level,
      remove_family_member_popup,
      showAddCreditPopup
    } = this.state;
    const {
      membership_status,
      config_status,
      set_notification_variable,
      show_member_error_popup,
      student_search_status
    } = this.props;

    const show_loading =
      membership_status === 'loading' ||
      config_status === 'loading' ||
      aff_loading ||
      z_index_loading ||
      student_search_status === 'loading';
    const meta_title = student_details.first_name
      ? `${student_details.first_name}'s Profile`
      : 'Student Profile';
    const clientDomain =
      client_domain === 'lifestyle' || client_domain === '' ? 'MyYogaTeacher' : 'Lifetree Health';

    if (loading) return <CustomLoading />;
    if (book_session) {
      return (
        <BookSession
          close_all_popups={this.close_all_popups}
          student_uuid={student_uuid}
          details={student_details}
          set_notification_variable={set_notification_variable}
        />
      );
    }
    if (edit_profile) {
      return (
        <EditProfile
          close_profile={this.toggle_edit_profile}
          student_details={student_details}
          edit_profile_success={this.edit_profile_success}
          config_values={this.props.config_values}
        />
      );
    }
    return (
      <CommonHeader
        className={`${client_domain === 'backpain' ? 'bg-mytBisque' : ''}`}
        show_back
        loading={show_loading}
        title={`Student Profile: ${clientDomain}`}
        meta_title={meta_title}
      >
        <div className="w-full flex items-center justify-center gap-5">
          <p className="m-0 mb-5 py-1 px-5 text-sm bg-blue-500 w-fit text-white rounded-lg">
            {this.state.student_details?.signup_trial_days_offered} Days Free Trial
          </p>
          {this.state.student_details?.is_glean_customer === 1 && (
            <p className="m-0 mb-5 py-1 px-5 text-sm bg-green-500 w-fit text-white rounded-lg">
              Glean User
            </p>
          )}
        </div>
        <CustomModal show={notes_open} close={this.toggle_notes}>
          {this.render_notes()}
        </CustomModal>
        <CustomModal show={this.state.show_create_subscription} close={this.close_subsciption}>
          {this.render_subscription()}
        </CustomModal>
        <CustomModal show={ban_open} close={this.toggle_ban}>
          {this.render_ban()}
        </CustomModal>
        <CustomModal show={unban_open} close={this.toggle_unban}>
          {this.render_unban()}
        </CustomModal>
        <CustomModal show={this.state.add_credit} close={this.close_all_popups}>
          {this.render_add_credit()}
        </CustomModal>
        <CustomModal
          show={this.state.open_mem_del && !show_member_error_popup}
          close={this.close_cud_mem}
        >
          {this.render_del_mem()}
        </CustomModal>
        <CustomModal show={this.state.open_pause_create} close={this.close_cud_mem}>
          {this.render_create_pause()}
        </CustomModal>
        <CustomModal show={open_mem_ext} close={this.close_cud_mem}>
          {this.render_extend_membership()}
        </CustomModal>
        <CustomModal show={this.state.open_pause_delete} close={this.close_cud_mem}>
          {this.render_delete_pause()}
        </CustomModal>
        <CustomModal show={this.state.open_mem_res} close={this.close_cud_mem}>
          {this.render_mem_resume()}
        </CustomModal>
        <CustomModal show={this.state.open_add_roadmap} close={this.close_cud_mem}>
          {this.render_add_roadmap()}
        </CustomModal>
        <CustomModal show={this.state.open_create_roadmap} close={this.close_cud_mem}>
          {this.render_create_roadmap()}
        </CustomModal>
        <CustomModal show={this.state.open_affliate} close={this.close_cud_mem}>
          {this.render_confirm_affiliate()}
        </CustomModal>
        <CustomModal show={scholarship_open} close={this.toggle_scholarship}>
          {this.render_scholarship()}
        </CustomModal>
        <CustomModal show={add_student_referral} close={this.close_cud_mem}>
          {this.render_add_referral()}
        </CustomModal>
        <CustomModal show={this.state.open_family_plan} close={this.close_family_plan} isFullWidth>
          {this.render_add_student_to_family_member()}
        </CustomModal>
        <CustomModal show={this.state.show_raw_data} close={this.toggle_raw_data}>
          {this.render_raw_data()}
        </CustomModal>
        <CustomModal show={this.state.same_students_open} close={this.toggle_same_students}>
          {this.render_same_students()}
        </CustomModal>
        <CustomModal show={this.state.reset_trial_open} close={this.toggle_reset_trial}>
          {this.render_reset_trial()}
        </CustomModal>
        <CustomModal show={this.state.change_email_open} close={this.toggle_change_email}>
          {this.render_change_email()}
        </CustomModal>
        <CustomModal show={open_change_prepaid} close={this.toggle_change_prepaid}>
          {this.render_prepaid()}
        </CustomModal>
        <CustomModal show={unsubscribe_open} close={this.close_cud_mem}>
          {this.render_unsubscribe()}
        </CustomModal>
        <CustomModal show={show_concierge_modal} close={this.close_concierge_modal}>
          {this.render_concierge()}
        </CustomModal>
        <CustomModal show={extend_trial_period_poup} close={this.close_extend_trial_modal}>
          {this.render_extend_trial_period_popup()}
        </CustomModal>
        <CustomModal show={show_consult_form} isFullWidth close={this.close_consult_from}>
          {this._render_consult_form()}
        </CustomModal>
        <CustomModal show={show_upload_medical} close={this.close_upload_medical_popup}>
          {this.render_upload_medical_popup()}
        </CustomModal>
        <CustomModal show={is_know_student_better_open} close={this.show_know_student_better_popup}>
          {this.render_know_student_better()}
        </CustomModal>
        <CustomModal
          show={is_capture_activity_level}
          close={this.show_capture_activity_level_popup}
        >
          <CaptureActivityLevel />
        </CustomModal>
        <CustomModal
          show={show_back_pain_area}
          showCloseBtn
          close={this.toggle_back_pain_area_popup}
        >
          {this.render_back_pain_area()}
        </CustomModal>
        <CustomModal show={open_better_back_package} showCloseBtn close={this.close_cud_mem}>
          {this.render_better_back_package()}
        </CustomModal>
        <CustomModal show={show_easy_filter_popup} showCloseBtn close={this.close_easy_filter}>
          {this.render_easy_filter()}
        </CustomModal>
        <CustomModal
          show={show_easy_filter_history_popup}
          isFullWidth
          showCloseBtn
          close={this.close_easy_filter_history}
        >
          {this.render_easy_filter_history()}
        </CustomModal>
        <CustomModal show={remove_family_member_popup} close={this.close_cud_mem}>
          {this.render_remove_femaily_member()}
        </CustomModal>
        <CustomModal show={show_member_error_popup} close={this.close_member_error_popup}>
          {this.render_membership_error_popup()}
        </CustomModal>
        <CustomModal show={showAddCreditPopup} close={this.close_cud_mem}>
          <AddCreditForMembership
            closeCreditForMembership={this.close_cud_mem}
            studentUUID={student_uuid}
          />
        </CustomModal>
        {this.render_extra_sessions()}
        {student_details.is_banned === 1 ? (
          <div className="banned-user-container">Banned user</div>
        ) : null}
        {this.render_profile_buttons_new()}
        {this.render_profile_details()}
        {sms_window_open ? (
          <SmsWindow
            student_uuid={sms_window_student}
            student_name={`${student_details.first_name} ${student_details.last_name}`}
            close_fn={this.close_sms_window}
          />
        ) : null}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  membership_status: state.loading.membership_status,
  config_status: state.loading.config_status,
  config_values: state.home.config_values,
  iana_timezone: state.home.iana_timezone,
  search_students_list: state.students.search_students_list,
  student_search_status: state.loading.student_search_status,
  access_type: state.home.access_type,
  cms_user_name: state.home.cms_user_name,
  show_member_error_popup: state.loading.show_member_error_popup,
  membership_error_response: state.loading.membership_error_response,
  pause_error_response: state.loading.pause_error_response,
  show_pause_error: state.loading.show_pause_error
});

const mapDispatchToProps = (dispatch) => ({
  impersonate_user: (id, type, is_uk_user, client_domain) => {
    dispatch(actions.impersonate_user(id, type, is_uk_user, client_domain));
  },
  cud_membership_pause: (payload) => dispatch(actions.cud_membership_pause(payload)),
  cud_membership: (payload) => dispatch(actions.cud_membership(payload)),
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  },
  search_students: (payload) => {
    dispatch(actions.search_students(payload));
  },
  set_loading_variable: (key, payload) => {
    dispatch(actions.set_loading_variable(key, payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentProfile);
